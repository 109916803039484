import { useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { GET_CLAIM } from '../../../../shared';
import {
  CLAIM_UPLOAD_FLOW_SOURCE_EXISTING_CLAIM_DOCUMENT,
  CLAIM_UPLOAD_FLOW_SOURCE_NEW_CLAIM_DOCUMENT,
  CLAIM_UPLOAD_FLOW_SOURCE_UPLOAD_DOCUMENT,
  useClaimUploadFlowSource,
} from '../../claimUploadFlowSource';
import { DENTOLO_EDIT_STEP_DOCUMENT_DATE, PARAM_CLAIM_ID } from '../../edit/editClaimFormSteps';
import {
  DENTOLO_DOCUMENT_TYPE_INVOICE,
  DENTOLO_DOCUMENT_TYPE_TREATMENT_PLAN,
  DENTOLO_IS_EXISTING_CLAIM_ANSWER_NO,
  DENTOLO_IS_EXISTING_CLAIM_ANSWER_YES,
  DENTOLO_TREATMENT_CATEGORY_OTHERS,
} from '../../claimFormData/newClaimFormData';
import {
  DENTOLO_STEP_DOCUMENT_DATE,
  DENTOLO_STEP_DOCUMENT_TYPE,
  DENTOLO_STEP_EXISTING_CLAIMS,
  DENTOLO_STEP_EXISTING_CLAIM_QUESTION,
} from '../../new/newClaimsFormSteps';
import { isReimbursementPromised, isMissingInvoice, isMissingTreatmentPlan } from './claimUtils';

const getBackButtonLinkForNewReimbursement = (
  treatmentCategory,
  documentType,
  claimsCount,
  isExistingClaim
) => {
  if (
    treatmentCategory === DENTOLO_TREATMENT_CATEGORY_OTHERS &&
    documentType === DENTOLO_DOCUMENT_TYPE_INVOICE &&
    claimsCount > 0
  ) {
    if (isExistingClaim === DENTOLO_IS_EXISTING_CLAIM_ANSWER_YES) {
      return DENTOLO_STEP_EXISTING_CLAIMS;
    } else if (isExistingClaim === DENTOLO_IS_EXISTING_CLAIM_ANSWER_NO) {
      return DENTOLO_STEP_EXISTING_CLAIM_QUESTION;
    }
  }

  return DENTOLO_STEP_DOCUMENT_TYPE;
};

export const useUploadTreatmentDocumentDentoloBackButtonLink = (
  claimId,
  treatmentCategory,
  documentType,
  claimsCount,
  isExistingClaim
) => {
  const [source, withSource] = useClaimUploadFlowSource();

  switch (source) {
    case CLAIM_UPLOAD_FLOW_SOURCE_NEW_CLAIM_DOCUMENT:
      const backButtonLink = getBackButtonLinkForNewReimbursement(
        treatmentCategory,
        documentType,
        claimsCount,
        isExistingClaim
      );
      return withSource(backButtonLink);
    case CLAIM_UPLOAD_FLOW_SOURCE_EXISTING_CLAIM_DOCUMENT:
      return withSource(DENTOLO_STEP_EXISTING_CLAIMS);
    case CLAIM_UPLOAD_FLOW_SOURCE_UPLOAD_DOCUMENT:
      return `/user-claims-info/${claimId}`;
    default:
      return '/user-claims-info';
  }
};

const getNextButtonLinkForExistingClaim = (claim, source) => {
  //If the user goes through the New Reimbursement flow, we obtain information regarding the document
  if (
    source === CLAIM_UPLOAD_FLOW_SOURCE_NEW_CLAIM_DOCUMENT ||
    source === CLAIM_UPLOAD_FLOW_SOURCE_EXISTING_CLAIM_DOCUMENT
  ) {
    return DENTOLO_EDIT_STEP_DOCUMENT_DATE;
  }

  //If the user goes through the Upload Document flow, we check the status of the claim first
  if (!!claim) {
    const reimbursementPromised = isReimbursementPromised(claim);
    const missingInvoice = isMissingInvoice(claim);
    const missingTreatmentPlan = isMissingTreatmentPlan(claim);

    if (reimbursementPromised || missingInvoice || missingTreatmentPlan) {
      return DENTOLO_EDIT_STEP_DOCUMENT_DATE;
    }
  }
  return '';
};

export const useUploadTreatmentDocumentDentoloNextButtonLink = (claimId) => {
  const [source, withSource] = useClaimUploadFlowSource();

  const { data: claimData } = useQuery(GET_CLAIM, {
    variables: { id: Number(claimId) },
    skip: !claimId
  });

  if (!!claimId) {
    const nextButtonLink = getNextButtonLinkForExistingClaim(claimData?.claim, source);
    const nextButtonLinkWithClaimId = nextButtonLink.replace(PARAM_CLAIM_ID, claimId);
    return !!nextButtonLinkWithClaimId ? withSource(nextButtonLinkWithClaimId) : '';
  } else {
    return withSource(DENTOLO_STEP_DOCUMENT_DATE);
  }
};

export const usePrefillSomeFormFieldsForExistingDentoloClaim = (
  claimId,
  setTreatmentCategory,
  setDocumentType
) => {
  const [source] = useClaimUploadFlowSource();

  const { data: claimData } = useQuery(GET_CLAIM, {
    variables: { id: Number(claimId) },
    skip: !claimId
  });
  const claim = claimData?.claim;

  const reimbursementPromised = isReimbursementPromised(claim);
  const missingInvoice = isMissingInvoice(claim);
  const missingTreatmentPlan = isMissingTreatmentPlan(claim);

  useEffect(() => {
    if (source === CLAIM_UPLOAD_FLOW_SOURCE_UPLOAD_DOCUMENT) {
      if (reimbursementPromised || missingInvoice) {
        setTreatmentCategory(DENTOLO_TREATMENT_CATEGORY_OTHERS);
        setDocumentType(DENTOLO_DOCUMENT_TYPE_INVOICE);
      } else if (missingTreatmentPlan) {
        setTreatmentCategory(DENTOLO_TREATMENT_CATEGORY_OTHERS);
        setDocumentType(DENTOLO_DOCUMENT_TYPE_TREATMENT_PLAN);
      }
    } else if (source === CLAIM_UPLOAD_FLOW_SOURCE_EXISTING_CLAIM_DOCUMENT) {
      setTreatmentCategory(DENTOLO_TREATMENT_CATEGORY_OTHERS);
      setDocumentType(DENTOLO_DOCUMENT_TYPE_INVOICE);
    }
  }, [
    source,
    reimbursementPromised,
    missingInvoice,
    missingTreatmentPlan,
    setTreatmentCategory,
    setDocumentType,
  ]);
};
