import styled, { css } from 'styled-components';

import { Button } from '../Button';
import { color, boxShadow } from '../../../theme/functions';
import { min_width_md } from '../../../theme/breakpoints';

export const StyledSelectUserItemButton = styled(Button)`
  display: flex;
  align-items: center;
  padding: 1rem;
  width: 100%;
  background-color: ${color('white')};
  box-shadow: ${boxShadow('box_shadow_1')};
  border-radius: 5px;
  transition: opacity 0.3s ease-in-out;

  & + & {
    margin-top: 1rem;
  }

  ${({ active }) =>
    !active &&
    css`
      opacity: 0.5;
    `}

  &::after {
    content: none;
  }
`;

export const StyledUserItemLogo = styled.div`
  width: 4.5rem;
  height: 4.5rem;
  margin-right: 1rem;

  @media (min-width: ${min_width_md}) {
    width: 4.5rem;
    height: 4.5rem;
    margin-right: 1.5rem;
  }
`;

export const StyledUserItemName = styled.div`
  font-size: 1.25rem;
  font-weight: 600;
  text-decoration: underline;
`;

export const StyledUserItemPolicy = styled.div`
  margin-top: 0.5rem;
  font-size: 1rem;
`;
