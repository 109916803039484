import {
  INSURANCE_CATEGORY_DENTAL,
  INSURANCE_CATEGORY_PET_HEALTH,
  INSURANCE_CATEGORY_PET_LIABILITY,
  INSURANCE_CATEGORY_HEALTH,
  INSURANCE_TYPE_DENTOLO,
  INSURANCE_TYPE_PETOLO,
  INSURANCE_TYPE_VITOLO,
  SERVICE_EMAIL_DENTOLO,
  SERVICE_EMAIL_PETOLO,
  SERVICE_EMAIL_VITOLO,
} from '../shared';

export const getServiceEmail = (insuranceType) => {
    switch (insuranceType) {
      case INSURANCE_TYPE_DENTOLO:
        return SERVICE_EMAIL_DENTOLO;
      case INSURANCE_TYPE_PETOLO:
        return SERVICE_EMAIL_PETOLO;
      case INSURANCE_TYPE_VITOLO:
        return SERVICE_EMAIL_VITOLO;
      default:
        return SERVICE_EMAIL_DENTOLO;
    }
};

export const getInsuredPersonName = (customer) => {
  if (!customer?.insuredPersonIsCustomer) {
    return `${customer?.insuredPerson?.firstName} ${customer?.insuredPerson?.lastName}`;
  } else {
    return `${customer?.firstName} ${customer?.lastName}`;
  }
};

export const getNameAccordingToInsuranceCategory = (customer) => {
  const { firstName, lastName, insuranceCategory } = customer || {};
  const customername = `${firstName || ''} ${lastName || ''}`;

  switch (insuranceCategory) {
    case INSURANCE_CATEGORY_DENTAL:
    case INSURANCE_CATEGORY_HEALTH:
      return getInsuredPersonName(customer);
    case INSURANCE_CATEGORY_PET_HEALTH:
      return customer?.insuredPet?.name || customername;
    case INSURANCE_CATEGORY_PET_LIABILITY:
      return customer?.insuredPet?.name || customername;

    default:
      return getInsuredPersonName(customer);
  }
};
