import React from 'react';
import styled from 'styled-components';
import { palette } from '../../constants/Theme';

const StyledPath = styled.path`
  fill: ${({ fill }) => fill || palette.main_color_2};
`;

const IconExternalLink = (props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <StyledPath
        d="M17.4854 8.01298C16.9331 8.01196 16.4862 7.56342 16.4872 7.01114L16.4957 5.42231L12.8949 7.96101C12.4718 8.31601 11.8411 8.26083 11.4861 7.83775C11.1311 7.41468 11.1862 6.78392 11.6093 6.42892L15.3649 3.76029L13.2502 3.75C12.6979 3.74889 12.2511 3.30027 12.2522 2.74799C12.2533 2.1957 12.7019 1.74889 13.2542 1.75L17.1996 1.76398C17.9193 1.76537 18.5014 2.34973 18.5 3.06937L18.4872 7.01482C18.4862 7.56711 18.0376 8.014 17.4854 8.01298Z"
        {...props}
      />
      <StyledPath
        d="M4.5 4.48469C3.39543 4.48469 2.5 5.38012 2.5 6.48469V15.4847C2.5 16.5893 3.39543 17.4847 4.5 17.4847H13.5C14.6046 17.4847 15.5 16.5893 15.5 15.4847V11.4847C15.5 10.9324 15.0523 10.4847 14.5 10.4847C13.9477 10.4847 13.5 10.9324 13.5 11.4847V15.4847H4.5V6.48469H9C9.55228 6.48469 10 6.03697 10 5.48469C10 4.9324 9.55228 4.48469 9 4.48469H4.5Z"
        {...props}
      />
    </svg>
  );
};

export default IconExternalLink;
