import React, { useState } from 'react';
import { Tabs } from 'react-tabs';
import { FormattedMessage } from 'react-intl';

import DentoloReferralInformation from './DentoloReferralInformation';
import DentoloReferralStatus from './DentoloReferralStatus';
import { Container, PageTitle } from '../../../../components/common';
import {
  StyledReferralTab,
  StyledReferralTabList,
  StyledReferralTabPanel,
} from '../ReferralPage.styled';

function DentoloReferral() {
  const [selectedTabIdx, setSelectedTabIdx] = useState(0);

  const handleSelectTab = (index) => {
    setSelectedTabIdx(index);
  };

  return (
    <Container>
      <PageTitle style={{ fontSize: '1.875rem' }} data-test-id="pagename_referral_dentolo">
        <FormattedMessage
          id="referral.page.dentolo.title"
          values={{ amount: 50, couponbrand: 'Amazon Gutschein' }}
        />
      </PageTitle>

      <Tabs
        selectedIndex={selectedTabIdx}
        selectedTabClassName="selected-referral-tab"
        onSelect={handleSelectTab}
      >
        <StyledReferralTabList>
          <StyledReferralTab>
            <FormattedMessage id="referral.page.tab.label.one" />
          </StyledReferralTab>
          <StyledReferralTab>
            <FormattedMessage id="referral.page.tab.label.two" />
          </StyledReferralTab>
        </StyledReferralTabList>

        <StyledReferralTabPanel>
          <DentoloReferralInformation />
        </StyledReferralTabPanel>

        <StyledReferralTabPanel>
          <DentoloReferralStatus />
        </StyledReferralTabPanel>
      </Tabs>
    </Container>
  );
}

export default DentoloReferral;
