import styled, { css } from 'styled-components';

import { color } from '../../theme/functions';
import { min_width_lg } from '../../theme/breakpoints';
import { THEME_DENTOLO, THEME_PETOLO, THEME_VITOLO } from '../../shared';

import { Input } from '../common';

const disabledFormLabelColor = {
  [THEME_DENTOLO]: color('gray'),
  [THEME_PETOLO]: color('gray'),
  [THEME_VITOLO]: color('dusty_blue'),
};

export const StyledForm = styled.form`
  background: #fff;
`;

export const StyledFormLegend = styled.legend`
  display: block;
  margin-bottom: 0.5em;
  color: #003264;
  font-size: 1em;
  font-weight: 300;
  line-height: 1.25em;
`;

export const StyledFormLabel = styled.label`
  display: block;
  margin-bottom: 0.5em;
  font-size: 1em;
  font-weight: 300;
  line-height: 1.25em;

  ${({ disabled, theme }) =>
    disabled
      ? css`
          color: ${disabledFormLabelColor[theme?.name] || color('gray')};
        `
      : css`
          color: ${color('main_blue')};
        `};
`;

export const StyledFormFieldset = styled.fieldset`
  margin-bottom: 1.5em;
`;

export const StyledGenderRadioInputs = styled.div`
  display: flex;
`;

export const StyledGenderRadioInputAndLabel = styled.div`
  display: flex;
  margin-right: 0.5em;
`;

export const StyledFormGroup = styled.div`
  margin-bottom: 1.5em;
`;

export const StyledInputGroup = styled.div`
  display: flex;
`;

export const StyledStreetInput = styled(Input)`
  flex: 4 1 0;
  margin-right: 1em;
`;

export const StyledHouseNumberInput = styled(Input)`
  flex: 1 1 0;
  margin-left: 1em;
`;

export const StyledPostCodeInput = styled(Input)`
  flex: 1 1 0;
  margin-right: 1em;
`;

export const StyledCityInput = styled(Input)`
  flex: 4 1 0;
  margin-left: 1em;
`;

export const StyledAdditionalDetailsText = styled.p`
  margin-top: 1.5em;
  margin-bottom: 1.5em;
`;

export const StyledButtonContainer = styled.div`
  margin-top: 1.5em;
  width: 100%;

  button {
    width: auto;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const StyledSectionTitle = styled.p`
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.375rem;
  margin-bottom: 1rem;
`;

export const StyledDigitalPaymentContainerTitle = styled(StyledSectionTitle)`
  margin-top: 3rem;
`;

export const StyledBillingDayRadioInputs = styled.div`
  display: flex;
  flex-direction: column;
`;
export const StyledBillingDayRadioInputAndLabel = styled.div`
  display: flex;
  margin-right: 0.5em;
  &:first-child {
    margin-bottom: 1rem;
  }
`;

export const StyledDigitalPaymentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${({ hasApplePay }) => (hasApplePay ? '49rem' : '45rem')};
  border-radius: 5px;

  @media only screen and (min-width: ${min_width_lg}) {
    height: ${({ hasApplePay }) => (hasApplePay ? '48rem ' : '44rem')};
  }
`;
