//prod
export const designAssets = '/images/design-assets/';
export const userAreaIconsDentolo = '/images/user-area-icons/dentolo/';
export const userAreaIconsPetolo = '/images/user-area-icons/petolo/';
export const userAreaIconsOnboard = '/images/user-area-icons/onboard/';
export const icons = '/images/user-area-icons/general/';
export const logos = '/images/logos/';
export const sliderAssets = '/images/slider-assets/';

export const imgLinks = {
  // slider images
  'betterdoc.desktop': `${sliderAssets}vitolo_better_doc_desktop_v1.png`,
  'betterdoc.mobile': `${sliderAssets}vitolo_better_doc_mobile_v1.png`,
  'fressnapf.desktop': `${sliderAssets}image_cover_fn_friends_desktop.svg`,
  'fressnapf.mobile': `${sliderAssets}image_cover_fn_friends_mobile.svg`,
  'crossselling.underline': `${sliderAssets}vitolo_cross_selling_underline.png`,
  'crossselling.arrow': `${sliderAssets}vitolo_cross_selling_arrow.png`,
  'crossselling.dental.desktop': `${sliderAssets}vitolo_cross_selling_dental_desktop.png`,
  'crossselling.dental.mobile': `${sliderAssets}vitolo_cross_selling_dental_mobile.png`,
  'crossselling.pet.desktop': `${sliderAssets}vitolo_cross_selling_pet_desktop_v1.png`,
  'crossselling.pet.mobile': `${sliderAssets}vitolo_cross_selling_pet_mobile_v1.png`,

  // new icons
  'icon.failed': `${designAssets}icon.failed.svg`,
  'icon.success': `${designAssets}icon.success.svg`,
  'confirm.time': `${designAssets}confirm.time.svg`,
  'flying.money': `${designAssets}flying.money.svg`,
  'icon.delete.circle.dental': `${designAssets}icon.delete.circle.svg`,
  'icon.delete.circle.pet': `${designAssets}icon.delete.circle.pet.svg`,
  'icon.menu.green.tooth.lg': `${designAssets}icon.green.circle.tooth-lg.svg`,
  'icon.menu.orange.paw.lg': `${designAssets}icon.orange.circle.paw-lg.svg`,
  'icon.menu.info': `${designAssets}icon.info.svg`,
  'icon.menu.person': `${designAssets}icon.person.svg`,
  'icon.menu.doc': `${designAssets}icon.doc.svg`,
  'icon.menu.money': `${designAssets}icon.money.svg`,
  'icon.menu.headphone': `${designAssets}icon.headphone.svg`,
  'icon.menu.phone': `${designAssets}icon.contact.phone.svg`,
  'icon.menu.email': `${designAssets}icon.contact.email.svg`,
  'info.success': `${designAssets}info.success.svg`,
  'info.warning': `${designAssets}info.failure.svg`,
  'notification.success': `${designAssets}notification.success.svg`,
  'notification.warning': `${designAssets}notification.warning.svg`,
  'notification.processing': `${designAssets}notification.processing.svg`,
  'notification.information': `${designAssets}notification.information.svg`,

  'icon.plus.teal': `${designAssets}icon.plus.teal.svg`,
  'icon.download.dental': `${designAssets}icon.download.dental.svg`,
  'icon.download.pet': `${designAssets}icon.download.pet.svg`,
  'icon.menu.green.tooth': `${designAssets}icon.green.circle.tooth.svg`,
  'icon.menu.orange.paw': `${designAssets}icon.orange.circle.paw.svg`,
  'icon.plus.orange': `${designAssets}icon.plus.petolo.svg`,
  'icon.pencil.blue.filled': `${designAssets}icon.pencil.blue.filled.svg`,
  'icon.pencil.orange.filled': `${designAssets}icon.pencil.orange.filled.svg`,
  'icon.telephone.blue.filled': `${designAssets}icon.telephone.blue.filled.svg`,
  'icon.telephone.orange.filled': `${designAssets}icon.telephone.orange.filled.svg`,
  'icon.contribution.blue.filled': `${designAssets}icon.contribution.blue.filled.svg`,

  //
  'icon.failure': `${userAreaIconsDentolo}icon.failure.svg`,
  'icon.claim-success': `${userAreaIconsDentolo}icon.claim-success.svg`,
  'icon.claim-warning': `${userAreaIconsDentolo}icon.claim-warning.svg`,
  'icon.user': `${userAreaIconsDentolo}icon.user.svg`,
  'icon.policy.shield': `${userAreaIconsDentolo}icon.policy.shield.svg`,
  'icon.female.dentist': `${userAreaIconsDentolo}icon.female.dentist.svg`,
  'icon.claim.hand': `${userAreaIconsDentolo}icon.claim.hand.svg`,
  'logo.dentolo.faircare.white.medium': `${logos}logo.dentolo.logo.white.new.svg`,

  'icons.help': `${userAreaIconsDentolo}icon.on.board.help.svg`,
  'icons.play': `${userAreaIconsDentolo}icon.on.board.svg`,
  'icon.info': `${icons}icon.info-24x24.svg`,
  'icon.true': `${icons}icon.true-24x24.svg`,
  'icon.back.arrow': `${icons}icon.back.arrow.svg`,
  'icon.contact.info': `${userAreaIconsDentolo}icon.contact.info.svg`,
  'icon.pin': `${userAreaIconsDentolo}icon.pin.svg`,
  'icon.phone': `${userAreaIconsDentolo}icon.phone.svg`,
  'icon.globe': `${userAreaIconsDentolo}icon.globe.svg`,
  'icon.hours': `${userAreaIconsDentolo}icon.hours.svg`,
  'icon.accordion.arrow.down': `${userAreaIconsDentolo}icon.accordion.arrow.down.svg`,
  'icon.x': `${userAreaIconsDentolo}icon.x.svg`,
  'icon.upload.loader.outer': `${userAreaIconsDentolo}icon.upload.loader.outer.svg`,
  'icon.upload.loader.inner': `${userAreaIconsDentolo}icon.upload.loader.inner.svg`,
  'icon.user.claim.onboard.hand': `${userAreaIconsOnboard}icon.user.claim.onboard.hand.svg`,
  'icon.user.claim.onboard.euro': `${userAreaIconsOnboard}icon.user.claim.onboard.euro.svg`,
  'icon.user.claim.onboard.phone': `${userAreaIconsOnboard}icon.user.claim.onboard.phone.svg`,
  'icon.user.claim.onboard.tablet': `${userAreaIconsOnboard}icon.user.claim.onboard.tablet.svg`,
  'icon.user.claim.onboard.laptop': `${userAreaIconsOnboard}icon.user.claim.onboard.laptop.svg`,
  'icon.user.claim.onboard.rechnung': `${userAreaIconsOnboard}icon.user.claim.onboard.rechnung.svg`,
  'icon.user.claim.onboard.success': `${userAreaIconsOnboard}icon.user.claim.onboard.success.svg`,
  'icon.user.claim.onboard.big.success': `${userAreaIconsOnboard}icon.user.claim.onboard.big.success.svg`,
  'icon.user.claim.onboard.coin': `${userAreaIconsOnboard}icon.user.claim.onboard.coin.svg`,
  'icon.user.claim.onboard.coins': `${userAreaIconsOnboard}icon.user.claim.onboard.coins.svg`,
  'icon.user.claim.onboard.stars': `${userAreaIconsOnboard}icon.user.claim.onboard.stars.svg`,
  'icon.home.onboard.laptop.logo': `${userAreaIconsOnboard}icon.home.onboard.laptop.logo.svg`,
  'icon.home.onboard.tablet.logo': `${userAreaIconsOnboard}icon.home.onboard.tablet.logo.svg`,
  'icon.home.onboard.phone.logo': `${userAreaIconsOnboard}icon.home.onboard.phone.logo.svg`,
  'icon.home.onboard.map.pin': `${userAreaIconsOnboard}icon.home.onboard.map.pin.svg`,
  'icon.home.onboard.map': `${userAreaIconsOnboard}icon.home.onboard.map.svg`,
  'icon.home.onboard.dentolo.card': `${userAreaIconsOnboard}icon.home.onboard.dentolo.card.new.svg`,
  'icon.home.onboard.star': `${userAreaIconsOnboard}icon.home.onboard.star.svg`,
  'icon.home.onboard.hand': `${userAreaIconsOnboard}icon.home.onboard.hand.svg`,

  'icon.fill.heart': `${userAreaIconsDentolo}icon.fill.heart.svg`,
  'icon.blank.heart': `${userAreaIconsDentolo}icon.empty.heart.svg`,
  'icon.close': `${icons}icon.close.svg`,

  'icon.caret.right': `${icons}icon.caret-right-24x24.svg`,
  'icon.contact.menu': `${userAreaIconsDentolo}icon.contact.menu.svg`,
  'icon.helpcenter.menu': `${userAreaIconsDentolo}icon.helpcenter.menu.svg`,
  'icon.helpcenter.menu.burger': `${userAreaIconsDentolo}icon.helpcenter.menu.burger.svg`,
  'icon.user.amazon.voucher': `${userAreaIconsDentolo}amazon-voucher.transparent.svg`,
  'icon.user.gift.voucher': `${userAreaIconsDentolo}gift-voucher.referral.svg`,
  'icon.user.referral.voucher.sm': `${designAssets}icon.user.referral.voucher.sm.svg`,
  'icon.user.referral.voucher.lg': `${designAssets}icon.user.referral.voucher.lg.svg`,
  'icon.user.referral.present': `${designAssets}icon.user.referral.present.svg`,
  'icon.user.referral.car': `${designAssets}icon.user.referral.car.svg`,
  'icon.user.referral.fb': `${designAssets}fb.referral.logo.svg`,
  'icon.user.referral.email': `${designAssets}email.referral.logo.svg`,
  'icon.user.referral.whatsapp': `${designAssets}whatsup.referral.logo.svg`,
  'image.cover.referral.dental.lg': `${designAssets}referral-cover-image-dental-lg.jpg`,
  'image.cover.referral.dental.sm': `${designAssets}referral-cover-image-dental-sm.jpg`,

  'icon.user.referral.pet.present': `${designAssets}icon.user.referral.pet.present.svg`,
  'icon.user.referral.pet.voucher.sm': `${designAssets}icon.user.referral.pet.voucher.sm.png`,
  'icon.user.referral.pet.voucher.lg': `${designAssets}icon.user.referral.pet.voucher.lg.png`,
  'illustration.petolo.referral.fressnapf.voucher.sm': `${designAssets}petolo/illustration.petolo.referral.fressnapf.voucher.sm.jpg`,
  'illustration.petolo.referral.fressnapf.voucher.lg': `${designAssets}petolo/illustration.petolo.referral.fressnapf.voucher.lg.jpg`,
  'image.petolo.referral.fressnapf.voucher': `${designAssets}petolo/image.petolo.referral.fressnapf.voucher.png`,
  'icon.user.join.referral.pet.sm': `${designAssets}icon.user.join.referral.pet.sm.png`,
  'icon.user.join.referral.pet.lg': `${designAssets}icon.user.join.referral.pet.lg.png`,
  'icon.user.referral.pet.fb': `${designAssets}fb.referral.pet.logo.svg`,
  'icon.user.referral.pet.email': `${designAssets}email.referral.pet.logo.svg`,
  'icon.user.referral.pet.whatsapp': `${designAssets}whatsup.referral.pet.logo.svg`,
  'illustration.join.referral.dental': `${designAssets}illustration.join.referral.dental.svg`,

  'icon.payment.overview.background': `${logos}payment-overview-720x222.png`,
  'icon.payment.details': `${userAreaIconsDentolo}flying-money-hand.svg`,
  'icon.error.message.placeholder': `${userAreaIconsDentolo}error-placeholder-background-760-507.png`,

  // 404 pages

  'dentolo.404.page': `${designAssets}dentolo-404.svg`,
  'petolo.404.page': `${designAssets}petolo-404.svg`,

  // cancellation contract
  'icon.user.dental.cancellation.contract.mobile': `${designAssets}cancellation-dental-mobile.png`,
  'icon.user.dental.cancellation.contract.desktop': `${designAssets}cancellation-dental-desktop.png`,
  'icon.user.pet.cancellation.contract.mobile': `${designAssets}cancellation-pet-mobile.png`,
  'icon.user.pet.cancellation.contract.desktop': `${designAssets}cancellation-pet-desktop.png`,
  'icon.user.health.cancellation.contract.desktop': `${designAssets}vitolo/illustration.vitolo.cancellation.desktop.svg`,
  'icon.user.health.cancellation.contract.mobile': `${designAssets}vitolo/illustration.vitolo.cancellation.mobile.svg`,

  'icon.user.dental.cancellation.success': `${designAssets}cancellation-dental-success.png`,
  'icon.user.pet.cancellation.success': `${designAssets}cancellation-pet-success.png`,

  // Header Menu
  'icon.menu.green.doc': `${userAreaIconsDentolo}icon.green.circle.doc.svg`,
  'icon.menu.green.headphone': `${userAreaIconsDentolo}icon.green.circle.headphone.svg`,
  'icon.menu.green.info': `${userAreaIconsDentolo}icon.green.circle.info.svg`,
  'icon.menu.green.money': `${userAreaIconsDentolo}icon.green.circle.money.svg`,
  'icon.menu.green.person': `${userAreaIconsDentolo}icon.green.circle.person.svg`,
  'icon.menu.green.phone': `${userAreaIconsDentolo}icon.green.circle.phone.svg`,

  'icon.menu.dentolo.logo': `${logos}logo.dentolo.small-113x32.svg`,
  'icon.menu.accordion.arrow.down': `${icons}icon.caret-down-24x24.svg`,
  'icon.menu.blue.mark': `${icons}icon_checkbox_32x32_selected_simple.svg`,
  'icon.menu.dadirekt.dark': `${logos}da_direkt_logo_new_dark.svg`,

  // Petolo
  'icon.menu.petolo.logo': `${userAreaIconsPetolo}icon-petolo-logo-blue.svg`,
  'icon.menu.orange.doc': `${userAreaIconsDentolo}icon.orange.circle.doc.svg`,
  'icon.menu.orange.headphone': `${userAreaIconsDentolo}icon.orange.circle.headphone.svg`,
  'icon.menu.orange.info': `${userAreaIconsDentolo}icon.orange.circle.info.svg`,
  'icon.menu.orange.money': `${userAreaIconsDentolo}icon.orange.circle.money.svg`,
  'icon.menu.orange.person': `${userAreaIconsDentolo}icon.orange.circle.person.svg`,
  'icon.menu.orange.phone': `${userAreaIconsDentolo}icon.orange.circle.phone.svg`,
  'icon.petolo.check.orange': `${userAreaIconsPetolo}icon-success-orange.svg`,
  'icon.checked.orange': `${userAreaIconsPetolo}icon-checked-orange.svg`,

  'icon.orange.claim-warning': `${userAreaIconsPetolo}icon-warning-orange.svg`,

  // First Vet
  'icon.petolo.firstvet': `${userAreaIconsDentolo}petolo.firstvet.logo.png`,
  'icon.apple.store': `${userAreaIconsDentolo}icon.apple.store.badge.png`,
  'icon.google.play': `${userAreaIconsDentolo}icon.google.play.badge.png`,
  'icon.online.vet.slider.one': `${designAssets}petolo/icon-number-one.svg`,
  'icon.online.vet.slider.two': `${designAssets}petolo/icon-number-two.svg`,
  'icon.online.vet.slider.three': `${designAssets}petolo/icon-number-three.svg`,
  'icon.petolo.desktop.logo': `${userAreaIconsPetolo}icon-desktop-petolo.svg`,
  'icon.petolo.hand.money': `${userAreaIconsPetolo}icon-hand-money-orange.svg`,
  'icon.petolo.mobile.logo': `${userAreaIconsPetolo}icon-mobile-petolo.svg`,
  'icon.petolo.blue': `${userAreaIconsPetolo}icon-petolo-blue.svg`,
  'icon.petolo.card.no.stars': `${userAreaIconsPetolo}icon-petolo-card-no-starts.svg`,
  'icon.petolo.shield.orange': `${userAreaIconsPetolo}icon-shield-orange.svg`,
  'icon.petolo.star.one': `${userAreaIconsPetolo}icon-star-one.svg`,
  'icon.petolo.star.two': `${userAreaIconsPetolo}icon-star-two.svg`,
  'icon.petolo.star.three': `${userAreaIconsPetolo}icon-star-three.svg`,
  'icon.petolo.tablet.logo': `${userAreaIconsPetolo}icon-tablet-petolo.svg`,
  'icon-checked-stroke-orange': `${userAreaIconsPetolo}icon-checked-stroke-orange.svg`,

  //New Claim Creation
  'icon.other.treatments': `${designAssets}dentolo/icon.other.treatments.svg`,
  'icon.teeth.cleaning': `${designAssets}dentolo/icon.teeth.cleaning.svg`,
  'icon.teeth.filling': `${designAssets}dentolo/icon.teeth.filling.svg`,

  //Fressnapf
  'fressnapf.banner': `${designAssets}petolo/dr-fressnapf-banner.png`,

  // InsureAnotherPetBanner
  'image.insure.another.pet.banner': `${designAssets}image-insure-another-pet-banner.svg`,

  // vitolo
  'logo.text.vitolo': `${designAssets}vitolo/logo-text-vitolo.svg`,
  'icon.gift.violet': `${designAssets}vitolo/icon-gift-violet.svg`,
  'icon.pencil.filled.violet': `${designAssets}vitolo/icon-pencil-filled-violet.svg`,
  'icon.check.vitolo.stroke': `${designAssets}vitolo/icon-check-stroke.svg`,
  'icon.vitolo.heart.violet': `${designAssets}vitolo/icon-vitolo-heart-violet.svg`,
  'icon.download.vitolo': `${designAssets}vitolo/icon-download-violet.svg`,
  'icon.telephone.filled.violet': `${designAssets}vitolo/icon-telephone-filled-violet.svg`,
  'icon.delete.circle.violet': `${designAssets}vitolo/icon.delete.circle.violet.svg`,
  'icon.plus.violet': `${designAssets}vitolo/icon.plus.violet.svg`,

  // pending approval
  'illustration.pending_approval.vitolo.desktop': `${designAssets}vitolo/illustration.vitolo.pending.desktop.png`,
  'illustration.pending_approval.vitolo.mobile': `${designAssets}vitolo/illustration.vitolo.pending.mobile.png`,

  'icon.vitolo.underline': `${designAssets}vitolo/icon.vitolo.underline.svg`,
};
