// adding datadog initialization to the top
import './configs/datadog.config';

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import cssVars from 'css-vars-ponyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import { IntlProvider } from 'react-intl';
import { datadogLogs } from '@datadog/browser-logs';

import { apolloClient } from './configs/apollo.config';
import { polyfillReactIntl } from './locale/polyfillReactIntl';
import { DEFAULT_LOCALE } from './constants/DefaultValues';
import { messages } from './locale/messages';

import App from './App';
import ErrorBoundary from './components/ErrorBoundary';
import { ErrorPlaceholder } from './components/ui';

// TODO: configure properly if IE support required
cssVars({
  include: 'style',
  onlyLegacy: true,
  watch: true,
});

const locale = DEFAULT_LOCALE;

const polyfillReactIntlWithErrorReporting = async () => {
  try {
    await polyfillReactIntl(locale);
  } catch (error) {
    console.error(error);
    const errorLog = `PolyfillError: ${error}`;

    // # logging `PolyfillError` to datadog
    datadogLogs.logger.error(errorLog, {}, error);
  }
};

const rootElement = document.getElementById('root');

polyfillReactIntlWithErrorReporting().then(() => {
  ReactDOM.render(
    <IntlProvider locale={locale} messages={messages[locale]}>
      <ErrorBoundary datadog={datadogLogs} fallback={<ErrorPlaceholder />}>
        <ApolloProvider client={apolloClient}>
          <App />
        </ApolloProvider>
      </ErrorBoundary>
    </IntlProvider>,
    rootElement
  );
});
