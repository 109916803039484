import { useQuery } from '@apollo/client';
import { GET_VIDEO_CONSULTATIONS } from '../../../../shared';
import {
  CLAIM_UPLOAD_FLOW_SOURCE_EXISTING_CLAIM_DOCUMENT,
  useClaimUploadFlowSource,
} from '../../claimUploadFlowSource';
import {
  PARAM_CLAIM_ID,
  PETOLO_EDIT_STEP_DOCUMENT_DATE,
  PETOLO_EDIT_STEP_CUSTOMER_NOTE,
  PETOLO_EDIT_STEP_UPLOAD_DOCUMENTS,
  PETOLO_EDIT_STEP_TREATMENT_PRICE,
} from '../../edit/editClaimFormSteps';
import {
  getNewPetoloClaimFormDataValue,
  PET_HEALTH_RESAON_FOR_TREATMENT_EMERGENCY,
  PET_HEALTH_TREATMENT_CATEGORIES_WITH_BUDGET,
  PETOLO_LINK_VIDEO_CONSULTATION,
  PETOLO_LINK_VIDEO_CONSULTATION_YES,
  PETOLO_TREATMENT_CATEGORY_OTHERS,
} from '../../claimFormData/newClaimFormData';
import {
  PETOLO_STEP_DOCUMENT_TYPE,
  PETOLO_STEP_CUSTOMER_NOTE,
  PETOLO_STEP_UPLOAD_DOCUMENTS,
  PETOLO_STEP_VIDEO_CONSULTATION,
  PETOLO_STEP_TREATMENT_PRICE,
  PETOLO_STEP_CONSULTATION_QUESTION,
} from '../../new/newClaimsFormSteps';

const getBackButtonLinkForNewClaim = (
  hasVideoConsultations,
  linkVideoConsultationAnswer,
  videoConsultationId,
  treatmentCategories
) => {
  const isAutomatedFlow =
    Array.isArray(treatmentCategories) &&
    treatmentCategories.length === 1 &&
    treatmentCategories[0] !== PETOLO_TREATMENT_CATEGORY_OTHERS;

  if (hasVideoConsultations) {
    if (!!videoConsultationId) {
      return PETOLO_STEP_VIDEO_CONSULTATION;
    } else {
      //The customer did video consulations, but did not select one from the list

      if (isAutomatedFlow) {
        if (linkVideoConsultationAnswer === PETOLO_LINK_VIDEO_CONSULTATION_YES) {
          return PETOLO_STEP_VIDEO_CONSULTATION;
        } else {
          return PETOLO_STEP_CONSULTATION_QUESTION;
        }
      } else {
        return PETOLO_STEP_CUSTOMER_NOTE;
      }
    }
  } else {
    //The customer did not do any video consultations

    if (isAutomatedFlow) {
      return PETOLO_STEP_TREATMENT_PRICE;
    } else {
      return PETOLO_STEP_CUSTOMER_NOTE;
    }
  }
};

export const useSubmitClaimFormPetoloBackButtonLink = (
  claimId,
  videoConsultationId,
  treatmentCategories
) => {
  const [source, withSource] = useClaimUploadFlowSource();

  const { data: consultationData } = useQuery(GET_VIDEO_CONSULTATIONS);
  const hasVideoConsultations = Array.isArray(consultationData?.videoConsultations)
    ? consultationData.videoConsultations.length > 0
    : false;

  const linkVideoConsultation = getNewPetoloClaimFormDataValue(PETOLO_LINK_VIDEO_CONSULTATION);

  if (!!claimId && source === CLAIM_UPLOAD_FLOW_SOURCE_EXISTING_CLAIM_DOCUMENT) {
    const backButtonLinkWithClaimId = PETOLO_EDIT_STEP_TREATMENT_PRICE.replace(
      PARAM_CLAIM_ID,
      claimId
    );
    return withSource(backButtonLinkWithClaimId);
  } else if (!!claimId) {
    const backButtonLinkWithClaimId = PETOLO_EDIT_STEP_CUSTOMER_NOTE.replace(
      PARAM_CLAIM_ID,
      claimId
    );
    return withSource(backButtonLinkWithClaimId);
  } else {
    const backButtonLink = getBackButtonLinkForNewClaim(
      hasVideoConsultations,
      linkVideoConsultation,
      videoConsultationId,
      treatmentCategories
    );
    return withSource(backButtonLink);
  }
};

export const useEditDetailsLinkPetolo = (claimId) => {
  const [, /*source*/ withSource] = useClaimUploadFlowSource();

  if (!!claimId) {
    const editButtonLink = PETOLO_EDIT_STEP_DOCUMENT_DATE.replace(PARAM_CLAIM_ID, claimId);
    return withSource(editButtonLink);
  } else {
    return withSource(PETOLO_STEP_DOCUMENT_TYPE);
  }
};

export const useEditDocumentsLinkPetolo = (claimId) => {
  const [, /*source*/ withSource] = useClaimUploadFlowSource();

  if (!!claimId) {
    const editDocumentsLink = PETOLO_EDIT_STEP_UPLOAD_DOCUMENTS.replace(PARAM_CLAIM_ID, claimId);
    return withSource(editDocumentsLink);
  } else {
    return withSource(PETOLO_STEP_UPLOAD_DOCUMENTS);
  }
};

export const treatmentCategoriesAccordingToReasonForTreatment = (
  treatmentCategories,
  reasonForTreatment
) => {
  if (Array.isArray(treatmentCategories)) {
    if (reasonForTreatment === PET_HEALTH_RESAON_FOR_TREATMENT_EMERGENCY) {
      return treatmentCategories.map((category) => `${category}_sos`);
    } else {
      return treatmentCategories.map((category) =>
        PET_HEALTH_TREATMENT_CATEGORIES_WITH_BUDGET.includes(category)
          ? `${category}_health`
          : category
      );
    }
  }
  return treatmentCategories;
};
