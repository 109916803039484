import styled from 'styled-components';

import Paragraph from '../Paragraph';
import { Container } from '../../common';
import { min_width_md } from '../../../theme/breakpoints';

export const StyledErrorPlaceholder = styled(Container)`
  text-align: center;

  > img {
    max-width: 100%;
  }
`;

export const StyledErrorPlaceholderTitle = styled.h3`
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  font-weight: 700;

  @media (min-width: ${min_width_md}) {
    font-size: 2rem;
  }
`;

export const StyledErrorPlaceholderText = styled(Paragraph)`
  margin-top: 1rem;
  font-weight: 700;
  text-align: center;

  & + & {
    margin-top: 1rem;
  }

  > label {
    font-weight: 300;
  }
`;
