import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { useInsuranceInfo } from '../../hooks/useInsuranceInfo';
import { SUBJECTS_DEFAULT, daDirektReferralPageLinkMap, formSubjectsMap } from './contactUtils';

import { Button } from '../../components/ui';
import { Paper, TextArea } from '../../components/common';
import ContactInfoBanner from './ContactInfoBanner';
import IconInfoRoundedOutline from '../../components/Icons/IconInfoRoundedOutline';
import { StyledContactFormField, StyledContactFormTitle } from './Contact.styled';

const ContactForm = (props) => {
  const {
    maxLength,
    fullName,
    email,
    onFormSubmit,
    formLoading,
    body,
    setBody,
    subject,
    setSubject,
    isSubjectDaDirektReferral,
  } = props;

  const { category: insuranceCategory } = useInsuranceInfo();

  const formSubjects = formSubjectsMap[insuranceCategory] || SUBJECTS_DEFAULT;

  useEffect(() => {
    if (!Array.isArray(formSubjects) || formSubjects.length < 1) return;

    setSubject(formSubjects[0]);
  }, [formSubjects, setSubject]);

  const handleSubmitForm = (ev) => {
    ev && ev.preventDefault();

    onFormSubmit();
  };

  const DA_DIREKT_REFERRAL_PAGE_LINK = daDirektReferralPageLinkMap[insuranceCategory] || null;

  return (
    <>
      <StyledContactFormTitle>
        <FormattedMessage id="contact.page.main.form.compose" />
      </StyledContactFormTitle>

      <Paper style={{ marginBottom: '1rem' }}>
        <form onSubmit={handleSubmitForm}>
          <StyledContactFormField title={email} sameLine>
            <label>
              <FormattedMessage id="contact.page.main.form.sender" />
            </label>
            <>&nbsp;</>
            <span>{fullName}</span>
          </StyledContactFormField>

          {Array.isArray(formSubjects) && formSubjects.length > 0 && (
            <StyledContactFormField>
              <label htmlFor="formSubject">
                <FormattedMessage id="contact.page.main.form.reference" />
              </label>

              <select
                name="subject"
                id="formSubject"
                className="select-sm select-primary u3-mb-2"
                onChange={(e) => setSubject(e.target.value)}
                value={subject}
              >
                {formSubjects.map((subject) => (
                  <option key={subject} value={subject}>
                    {subject}
                  </option>
                ))}
              </select>
            </StyledContactFormField>
          )}

          {isSubjectDaDirektReferral && (
            <ContactInfoBanner
              icon={<IconInfoRoundedOutline />}
              messageId="contact.page.form.banner.subject.referral.dadirekt.info"
              messageValues={{
                br: <br />,
                a: (chunk) => (
                  <a target="_blank" rel="noopener noreferrer" href={DA_DIREKT_REFERRAL_PAGE_LINK}>
                    {chunk}
                  </a>
                ),
              }}
            />
          )}

          <StyledContactFormField>
            <label>
              <FormattedMessage id="contact.page.main.form.message" />
            </label>

            <TextArea
              style={{ marginBottom: '1rem' }}
              name="1"
              id="23"
              cols="30"
              rows="8"
              className="input-primary input-sm u3-mb-2"
              maxLength={maxLength}
              onChange={(e) => setBody(e.target.value)}
              value={body}
              required
            />
          </StyledContactFormField>

          <Button type="submit" disabled={formLoading} style={{ margin: '0 auto' }}>
            <FormattedMessage id="contact.page.main.form.send" />
          </Button>
        </form>
      </Paper>
    </>
  );
};

ContactForm.propTypes = {
  maxLength: PropTypes.string,
  fullName: PropTypes.string,
  email: PropTypes.string,
  onFormSubmit: PropTypes.func,
  formLoading: PropTypes.bool,
  body: PropTypes.string.isRequired,
  setBody: PropTypes.func.isRequired,
  subject: PropTypes.string.isRequired,
  setSubject: PropTypes.func.isRequired,
  isSubjectDaDirektReferral: PropTypes.bool,
};

export default ContactForm;
