import styled from 'styled-components';
import { color } from '../../../../theme/functions';

export const StyledCheckboxContainer = styled.div`
  display: flex;
  margin-top: 1em;

  span[data-label] {
    font-size: 0.875rem;
  }
`;

export const StyledHintContainer = styled.div`
  background-color: ${color('light_gray')};
  padding: 1rem;
  margin-top: 1rem;
  border-radius: 4px;
`;
