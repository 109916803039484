import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ReactMarkdown from 'react-markdown';

import { Notification } from '../../components/common';
import { isMigratedCatATBContract } from '../../components/Page/PageRenderForCatATBUpgrade';

import { StyledNotificationText } from '../../components/styled/Notification.styled';

export default function DeclineCatATBUpgradeBanner({ customerData }) {
  const intl = useIntl();

  const isMigrated = isMigratedCatATBContract(customerData);

  if (isMigrated) {
    return (
      <div style={{ marginTop: '1rem' }}>
        <Notification type="info">
          <StyledNotificationText $title>
            <FormattedMessage id="decline_cat_atb_upgrade.banner.title" />
          </StyledNotificationText>

          <StyledNotificationText>
            <ReactMarkdown>
              {intl.formatMessage({ id: 'decline_cat_atb_upgrade.banner.contents' })}
            </ReactMarkdown>
          </StyledNotificationText>
        </Notification>
      </div>
    );
  }
  return null;
}
