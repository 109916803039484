import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { useIntl, FormattedMessage } from 'react-intl';

import { useTrackDtEvent } from '../DtlTracker';
import { useInsuranceType } from '../hooks/useInsuranceType';
import {
  SIGN_CONTRACT,
  GET_DOCUMENTS,
  GET_CUSTOMER,
  GET_PAYMENT,
  useReady,
  documentsOrder,
  SIGN_OUT,
  INSURANCE_TYPE_DENTOLO,
  INSURANCE_TYPE_PETOLO,
  INSURANCE_TYPE_VITOLO,
} from '../shared';

import { Checkbox, Container, PageTitle } from './common';
import { DownloadLink, Button, Accordion, Paragraph } from './ui';
import {
  StyledSignDocsSeparator,
  StyledSignDocsPaper,
  StyledButtonContainer,
} from './styled/SignDocs.styled';
import { DECLINED, PENDING } from '../shared/contractStatus';

const getNextURL = (insuranceType) => {
  switch (insuranceType) {
    case INSURANCE_TYPE_DENTOLO:
      return '/intro-dentolo/1';
    case INSURANCE_TYPE_PETOLO:
      return '/intro-petolo/1';
    case INSURANCE_TYPE_VITOLO:
      return '/dashboard';
    default:
      return '/intro-dentolo/1';
  }
};

const trackDtEvent =
  (eventType = '', uuid, sendUserData) =>
  () => {
    const trackData = {
      userData: {
        eventType: eventType,
        time: new Date().toISOString(),
        url: window?.location?.href,
        uuid: uuid,
      },
    };
    sendUserData(trackData);
  };

const SignDocs = () => {
  let history = useHistory();
  const intl = useIntl();

  const ready = useReady();
  const [isLoading, setIsLoading] = useState(false);
  const [sendUserData] = useTrackDtEvent();
  const insuranceType = useInsuranceType();

  const { loading: customerLoading, data: customerData } = useQuery(GET_CUSTOMER);
  const uuid = customerData?.customer?.uuid;
  const contractStatus = customerData?.customer?.contract?.status;

  const [signOut] = useMutation(SIGN_OUT, {
    refetchQueries: [{ query: GET_CUSTOMER }],
  });

  useEffect(() => {
    if (!customerLoading) {
      if (contractStatus === PENDING) {
        history.push('/pending-approval');
      } else if (contractStatus === DECLINED) {
        signOut().then(() => history.push('/login?invalid=true'));
      }
    }
  }, [history, signOut, contractStatus, customerLoading]);

  const [signContract] = useMutation(SIGN_CONTRACT, {
    refetchQueries: [{ query: GET_CUSTOMER }, { query: GET_PAYMENT }],
  });

  const signContractFunc = async () => {
    const res = await signContract();
    if (res.data && res.data.signContract.errors.length < 1) {
      setIsLoading(false);
      const nextURL = getNextURL(insuranceType);
      history.push(nextURL);
    }
  };

  const [signDocs, setSignDocs] = useState(false);
  const getDocuments = useQuery(GET_DOCUMENTS);

  let documents = [];
  let extraDocuments = [];

  if (getDocuments?.data?.documents?.length) {
    let maxSortNum = documentsOrder.length;
    let documentsWithNums = getDocuments?.data?.documents?.map((item, index) => {
      for (const docOrder of documentsOrder) {
        if (item.name === docOrder.name) {
          return {
            ...item,
            num: docOrder.numContractDoc,
          };
        }
      }
      return {
        ...item,
        num: maxSortNum + index,
      };
    });

    const mainDocsSection = [
      'contract',
      'protocol',
      'Tarifblatt Akutschutz Modul',
      'Sondervereinbarung Probemonat',
    ];
    const sortedDocuments = documentsWithNums.sort((a, b) => a.num - b.num);
    sortedDocuments.forEach((item) => {
      if (mainDocsSection.includes(item?.name)) {
        documents.push(item);
      } else {
        extraDocuments.push(item);
      }
    });
  }

  const handleDocumentLinkClick = (doc) => {
    trackDtEvent(
      `click 'open in new tab' link: ${intl.formatMessage({
        id: `document.${doc?.name}`,
      })}`,
      uuid,
      sendUserData
    )();
  };

  const handleDocumentButtonClick = (doc) => {
    trackDtEvent(
      `click 'download icon': ${intl.formatMessage({
        id: `document.${doc?.name}`,
      })}`,
      uuid,
      sendUserData
    )();
  };

  const handleCheckboxChange = () => {
    trackDtEvent(`check 'confirm': ${!signDocs}`, uuid, sendUserData)();
    setSignDocs(!signDocs);
  };

  const handleSubmit = (ev) => {
    ev.preventDefault();
    setIsLoading(true);
    signContractFunc();

    trackDtEvent(`click 'Zum Kundenkonto'`, uuid, sendUserData)();
  };

  return (
    <CSSTransition in={ready && !!uuid} timeout={600} classNames="slow-fade" unmountOnExit>
      <Container>
        <PageTitle>
          <FormattedMessage id="sign.docs.title" />
        </PageTitle>

        <Paragraph>
          <FormattedMessage id="sign.docs.description" />
        </Paragraph>

        <StyledSignDocsPaper>
          {documents &&
            documents.map((doc) => {
              return (
                doc &&
                doc.url &&
                doc.name && (
                  <DownloadLink
                    link={doc?.url}
                    name={doc?.name}
                    key={doc?.name}
                    target="_blank"
                    onLinkClick={() => handleDocumentLinkClick(doc)}
                    onButtonClick={() => handleDocumentButtonClick(doc)}
                  >
                    <strong>
                      <FormattedMessage id={`document.${doc?.name}`} />
                    </strong>
                  </DownloadLink>
                )
              );
            })}

          <StyledSignDocsSeparator />

          <Accordion
            onExpand={trackDtEvent(`click 'anzeigen'`, uuid, sendUserData)}
            toggleTitle={intl.formatMessage({ id: 'sign.docs.accordion.toggle.text' })}
            showText={intl.formatMessage({ id: 'sign.docs.accordion.show.text' })}
            hideText={intl.formatMessage({ id: 'sign.docs.accordion.hide.text' })}
          >
            {extraDocuments.map((doc) => {
              return (
                doc &&
                doc.url &&
                doc.name && (
                  <DownloadLink
                    link={doc?.url}
                    name={doc?.name}
                    key={doc?.name}
                    target="_blank"
                    onLinkClick={() => handleDocumentLinkClick(doc)}
                    onButtonClick={() => handleDocumentButtonClick(doc)}
                  >
                    <strong>
                      <FormattedMessage id={`document.${doc?.name}`} />
                    </strong>
                  </DownloadLink>
                )
              );
            })}
          </Accordion>

          <StyledSignDocsSeparator />

          <Checkbox
            label={intl.formatMessage({ id: 'sign.docs.input.label.text' })}
            alignItems="flex-start"
            required={true}
            checked={signDocs}
            onChange={handleCheckboxChange}
          />
        </StyledSignDocsPaper>

        <StyledButtonContainer>
          <Button type="button" disabled={!signDocs || isLoading} onClick={handleSubmit}>
            <FormattedMessage id="sign.docs.go.to.account" />
          </Button>
        </StyledButtonContainer>
      </Container>
    </CSSTransition>
  );
};

export default SignDocs;
