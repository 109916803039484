import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { Container } from '../../../components/common';
import UploadTreatmentDocumentVitolo from '../components/UploadDocument/UploadTreatmentDocumentVitolo';
import SelectDocumentCreationDateVitolo from '../components/SelectDocumentCreationDate/SelectDocumentCreationDateVitolo';
import TreatmentAmountVitolo from '../components/TreatmentAmount/TreatmentAmountVitolo';
import SubmitClaimFormVitolo from '../components/SubmitClaimForm/SubmitClaimFormVitolo';
import CustomerNoteVitoloWithUploadVitolo from '../components/CustomerNote/CustomerNoteWithUploadVitolo';
import { useReady } from '../../../shared';
import {
  PARAM_CLAIM_ID,
  VITOLO_EDIT_STEP_UPLOAD_DOCUMENTS,
  VITOLO_EDIT_STEP_DOCUMENT_DATE,
  VITOLO_EDIT_STEP_TREATMENT_PRICE,
  VITOLO_EDIT_STEP_CUSTOMER_NOTE,
  VITOLO_EDIT_STEP_REVIEW,
} from './editClaimFormSteps';

const EditVitoloClaim = ({
  selectedDocuments,
  setSelectedDocuments,
  setClaimSubmitted,
  relevantClaims,
}) => {
  const ready = useReady();
  const { path } = useRouteMatch();

  const relevantClaimsCount = relevantClaims?.length || 0;

  return (
    <CSSTransition in={ready} timeout={600} classNames="slow-fade" unmountOnExit>
      <Container>
        <Route
          render={({ location }) => (
            <Switch location={location}>
              <Route
                exact
                path={path}
                render={({ match }) => {
                  const claimId = match?.params?.claimId;

                  return !isNaN(claimId) ? (
                    <Redirect
                      to={{
                        pathname: VITOLO_EDIT_STEP_UPLOAD_DOCUMENTS.replace(
                          PARAM_CLAIM_ID,
                          claimId
                        ),
                        search: location.search,
                      }}
                    />
                  ) : (
                    <Redirect to={`/user-claims-info`} />
                  );
                }}
              />

              <Route path={VITOLO_EDIT_STEP_UPLOAD_DOCUMENTS} exact>
                <UploadTreatmentDocumentVitolo
                  selectedDocuments={selectedDocuments}
                  setSelectedDocuments={setSelectedDocuments}
                  setClaimSubmitted={setClaimSubmitted}
                  claimsCount={relevantClaimsCount}
                />
              </Route>

              <Route exact path={VITOLO_EDIT_STEP_DOCUMENT_DATE}>
                <SelectDocumentCreationDateVitolo />
              </Route>

              <Route exact path={VITOLO_EDIT_STEP_TREATMENT_PRICE}>
                <TreatmentAmountVitolo />
              </Route>
              <Route exact path={VITOLO_EDIT_STEP_CUSTOMER_NOTE}>
                <CustomerNoteVitoloWithUploadVitolo
                  selectedDocuments={selectedDocuments}
                  setSelectedDocuments={setSelectedDocuments}
                />
              </Route>
              <Route exact path={VITOLO_EDIT_STEP_REVIEW}>
                <SubmitClaimFormVitolo
                  selectedDocuments={selectedDocuments}
                  setSelectedDocuments={setSelectedDocuments}
                  setClaimSubmitted={setClaimSubmitted}
                />
              </Route>
            </Switch>
          )}
        />
      </Container>
    </CSSTransition>
  );
};

export default EditVitoloClaim;
