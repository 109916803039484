import styled from 'styled-components';
import { Text } from '../../components/common';
import { Button } from '../../components/ui';
import { min_width_md } from '../../theme/breakpoints';

export const StyledIllustrationContainerDesktop = styled.div`
  width: 100%;
  display: none;

  @media (min-width: ${min_width_md}) {
    display: block;
  }

  & > svg {
    width: 100%;
    height: auto;
  }
`;

export const StyledIllustrationContainerMobile = styled(StyledIllustrationContainerDesktop)`
  display: block;

  @media (min-width: ${min_width_md}) {
    display: none;
  }
`;

export const StyledContractPendingApprovalContent = styled(Text)`
  margin-bottom: 1em;
  text-align: center;

  @media (min-width: ${min_width_md}) {
    margin-bottom: 1.5em;
  }
`;

export const StyledContractPendingApprovalButton = styled(Button)`
  margin: 0 auto;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'inherit')};

  @media (min-width: ${min_width_md}) {
    width: inherit;
  }
`;
