import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import {
  StyledContactInfoBanner,
  StyledContactInfoBannerText,
  StyledContactInfoBannerIconWrapper,
} from './Contact.styled';

const ContactInfoBanner = (props) => {
  const { icon, messageId, messageValues } = props;

  return (
    <StyledContactInfoBanner>
      {icon && <StyledContactInfoBannerIconWrapper>{icon}</StyledContactInfoBannerIconWrapper>}

      <StyledContactInfoBannerText>
        <FormattedMessage id={messageId} values={messageValues} />
      </StyledContactInfoBannerText>
    </StyledContactInfoBanner>
  );
};

export default ContactInfoBanner;

ContactInfoBanner.propTypes = {
  icon: PropTypes.node,
  messageId: PropTypes.string.isRequired,
  messageValues: PropTypes.object,
};
