import React from 'react';
import { useParams } from 'react-router-dom';
import { DASHBOARD_ONBOARD_KEY } from '../../../shared';
import { Onboard } from '../../ui';
import HomeOnboardCard1 from './HomeOnboardCard1';
import HomeOnboardCard2 from './HomeOnboardCard2';
import HomeOnboardCard3 from './HomeOnboardCard3';
import HomeOnboardCard4 from './HomeOnboardCard4';
import { Container } from '../../common';

const HomeOnboard = [
  {
    id: 'HomeOnboardCard1',
    name: HomeOnboardCard1,
    titleHtml: 'Willkommen im <br />Kundenkonto!',
    bodyText: 'Von hier aus können Sie alle Vorteile des dentolo-Kontos sicher und einfach nutzen.',
    btnText: 'Überspringen',
  },
  {
    id: 'HomeOnboardCard2',
    name: HomeOnboardCard2,
    titleHtml: 'Übersicht<br />dentolo Schutz',
    bodyText:
      'Sie können wichtige Informationen rund um Ihren dentolo Schutz einsehen und bearbeiten, sowie Kostenerstattungen beantragen.',
    btnText: 'Überspringen',
  },
  {
    id: 'HomeOnboardCard3',
    name: HomeOnboardCard3,
    titleHtml: 'dentolo Zahnärzte finden<br />und kennenlernen',
    bodyText:
      'Finden Sie jetzt qualitätsgeprüfte dentolo Zahnärzte in Ihrer Region und profitieren Sie von höheren Erstattungen.',
    btnText: 'Überspringen',
  },
  {
    id: 'HomeOnboardCard4',
    name: HomeOnboardCard4,
    titleHtml: 'Neue Funktionen und<br />exklusive Vorteile',
    bodyText:
      'dentolo Mitglieder können auf eine ständig wachsende Liste von Vorteilen zugreifen und profitieren durch den dentolo Schutz besonders stark bei dentolo Zahnärzten. ',
    btnText: 'Zum Kundenkonto',
    btnType: 'primary',
  },
];

const HomeOnboardDentolo = () => {
  let { id } = useParams();

  return (
    <Container>
      <Onboard
        initialIndex={id}
        name={DASHBOARD_ONBOARD_KEY}
        onboardingUrl="/intro-dentolo"
        mainPageUrl="/dashboard"
        schema={HomeOnboard}
      />
    </Container>
  );
};

export default HomeOnboardDentolo;
