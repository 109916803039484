import React from 'react';
import PropTypes from 'prop-types';

import { IconButton } from '../../../components/common';

import {
  StyledPromotionCarouselControls,
  StyledPromotionCarouselControlsDots,
  StyledPromotionCarouselControlsDotsContainer,
  StyledPromotionCarouselSeparator,
} from './PromotionCarousel.styled';

const PromotionCarouselControls = (props) => {
  const { slides, slideCount, current, onPrevious, onNext, onGoto } = props;

  if (slideCount <= 1) return null;

  return (
    <>
      <StyledPromotionCarouselSeparator />

      <StyledPromotionCarouselControls>
        <IconButton icon="icon.back.arrow" size="sm" onClick={onPrevious} />

        <StyledPromotionCarouselControlsDotsContainer>
          {slides.map((slide, idx) => (
            <StyledPromotionCarouselControlsDots
              key={slide?.id}
              active={current === idx}
              onClick={() => onGoto(idx)}
            />
          ))}
        </StyledPromotionCarouselControlsDotsContainer>

        <IconButton icon="icon.caret.right" size="sm" onClick={onNext} />
      </StyledPromotionCarouselControls>
    </>
  );
};

export { PromotionCarouselControls };

PromotionCarouselControls.propTypes = {
  slides: PropTypes.array,
  slideCount: PropTypes.number,
  current: PropTypes.number,
  onPrevious: PropTypes.func,
  onNext: PropTypes.func,
  onGoto: PropTypes.func,
};
