import { useClaimUploadFlowSource } from '../../claimUploadFlowSource';
import {
  PETOLO_STEP_DOCUMENT_TYPE,
  PET_HEALTH_STEP_TREATMENT_CATEGORIES,
} from '../../new/newClaimsFormSteps';

export const useSelectReasonForTreatmentBackButtonLink = () => {
  const [, /*source*/ withSource] = useClaimUploadFlowSource();
  return withSource(PET_HEALTH_STEP_TREATMENT_CATEGORIES);
};

export const useSelectReasonForTreatmentNextButtonLink = () => {
  const [, /*source*/ withSource] = useClaimUploadFlowSource();
  return withSource(PETOLO_STEP_DOCUMENT_TYPE);
};
