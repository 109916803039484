import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { min_width_md } from '../../theme/breakpoints';
import { color } from '../../theme/functions';

import { Button, Paragraph } from '.';
import { Icon, Paper } from '../common';
import { THEME_DENTOLO, THEME_PETOLO, THEME_VITOLO } from '../../shared';

const StyledInfoCard = styled(Paper)`
  padding: 2rem;

  > p {
    margin-top: 1.5rem;
  }
`;

const ParagraphWithIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  img {
    margin: 0 auto;
  }

  > p {
    margin-top: 1rem;
  }

  @media (min-width: ${min_width_md}) {
    flex-direction: row;
    align-items: center;

    img {
      margin: 0;
    }

    > p {
      margin-left: 2rem;
      margin-top: 0;
    }
  }
`;

const LinkStyles = css`
  display: block;
  margin-top: 1.5rem;
  width: fit-content;
  font-size: 1rem;
  font-weight: 450;
  color: ${color('main_blue')};

  @media (min-width: ${min_width_md}) {
    font-size: 1.25rem;
  }
`;

const StyledLink = styled(Link)`
  ${LinkStyles}
`;
const StyledHref = styled.a`
  ${LinkStyles}
`;

const icons = {
  success: 'info.success',
  failure: 'info.warning',
};

const getSuccessTipBackgroundColor = (theme) => {
  switch (theme?.name) {
    case THEME_DENTOLO:
      return color('clear_light_blue');
    case THEME_PETOLO:
      return color('ultra_light_orange');
    case THEME_VITOLO:
      return color('light_violet');
    default:
      return color('clear_light_blue');
  }
};
export const StyledSuccessTipContainer = styled.div`
  padding: 1rem;
  background-color: red;
  margin-top: 1.5rem;
  background-color: ${({ theme }) => {
    return getSuccessTipBackgroundColor(theme);
  }};
`;
export const StyledBoldSuccessTip = styled.p`
  font-weight: 700;
`;

export default function InfoCard(props) {
  const {
    mode,
    title,
    description,
    description2,
    button,
    tipQuestion,
    tipDescription,
    tipListTitle,
    tipItem1,
    tipItem2,
    ...rest
  } = props;

  return (
    <StyledInfoCard {...rest}>
      <ParagraphWithIcon>
        <Icon icon={icons[mode]} alt={mode} />

        <Paragraph fontWeight={700}>{title}</Paragraph>
      </ParagraphWithIcon>
      {description && <Paragraph>{description}</Paragraph>}
      {description2 && <Paragraph>{description2}</Paragraph>}
      {button && <InfoCardLink {...button} />}
      {tipQuestion && (
        <StyledSuccessTipContainer>
          <Paragraph>
            <StyledBoldSuccessTip>{tipQuestion}</StyledBoldSuccessTip>
            {tipDescription}
          </Paragraph>
          <Paragraph>
            {tipListTitle}
            <div>
              <li>{tipItem1}</li>
              <li>{tipItem2}</li>
            </div>
          </Paragraph>
        </StyledSuccessTipContainer>
      )}
    </StyledInfoCard>
  );
}

InfoCard.defaultProps = {
  mode: 'success',
};

InfoCard.propTypes = {
  mode: PropTypes.string.isRequired,
  titleHtml: PropTypes.string,
  textHtml: PropTypes.string,
};

function InfoCardLink(props) {
  const { text, func, href, to, target, variant } = props;

  if (func) {
    return (
      <Button variant={variant} onClick={func}>
        {text}
      </Button>
    );
  } else if (to) {
    return <StyledLink to={to}>{text}</StyledLink>;
  } else if (href) {
    return (
      <StyledHref href={href} target={target}>
        {text}
      </StyledHref>
    );
  } else return null;
}

const requiredPropsCheck = (expectedType) => (props, propName, componentName) => {
  const suppliedProp = props?.[propName];

  if (!props.func && !props.href && !props.to) {
    return new Error(
      `One of 'func' or 'href' or 'to' is required by '${componentName}' component.`
    );
  } else if (suppliedProp && typeof suppliedProp !== expectedType) {
    return new Error(
      `Invalid prop \`${propName}\` of type \`${typeof suppliedProp}\` supplied to \`${componentName}\`, expected \`${expectedType}\`.`
    );
  }
};

InfoCardLink.propTypes = {
  text: PropTypes.string.isRequired,
  func: requiredPropsCheck('func'),
  href: requiredPropsCheck('string'),
  to: requiredPropsCheck('string'),
  target: PropTypes.string,
};
