import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { Container } from '../../../components/common';
import { useReady } from '../../../shared';
import SelectDocumentTypeVitolo from '../components/SelectDocumentType/SelectDocumentTypeVitolo';
import UploadTreatmentDocumentVitolo from '../components/UploadDocument/UploadTreatmentDocumentVitolo';
import SelectDocumentCreationDateVitolo from '../components/SelectDocumentCreationDate/SelectDocumentCreationDateVitolo';
import TreatmentAmountVitolo from '../components/TreatmentAmount/TreatmentAmountVitolo';
import CustomerNoteVitoloWithUploadVitolo from '../components/CustomerNote/CustomerNoteWithUploadVitolo';
import SubmitClaimFormVitolo from '../components/SubmitClaimForm/SubmitClaimFormVitolo';
import SelectExistingClaimVitolo from '../components/SelectExistingClaim/SelectExistingClaimVitolo';
import {
  VITOLO_STEP_DOCUMENT_TYPE,
  VITOLO_STEP_TREATMENT_PRICE,
  VITOLO_STEP_UPLOAD_DOCUMENTS,
  VITOLO_STEP_DOCUMENT_DATE,
  VITOLO_STEP_CUSTOMER_NOTE,
  VITOLO_STEP_REVIEW,
  VITOLO_STEP_EXISTING_CLAIMS,
} from './newClaimsFormSteps';
const NewVitoloClaim = ({
  selectedDocuments,
  setSelectedDocuments,
  setClaimSubmitted,
  relevantClaims,
}) => {
  const ready = useReady();
  const { path } = useRouteMatch();

  const relevantClaimsCount = relevantClaims?.length || 0;

  return (
    <CSSTransition in={ready} timeout={600} classNames="slow-fade" unmountOnExit>
      <Container>
        <Route
          render={({ location }) => (
            <Switch location={location}>
              <Route
                exact
                path={path}
                render={() => (
                  <Redirect
                    to={{
                      pathname: VITOLO_STEP_DOCUMENT_TYPE,
                      search: location.search,
                    }}
                  />
                )}
              />
              <Route exact path={VITOLO_STEP_DOCUMENT_TYPE}>
                <SelectDocumentTypeVitolo />
              </Route>
              <Route exact path={VITOLO_STEP_UPLOAD_DOCUMENTS}>
                <UploadTreatmentDocumentVitolo
                  selectedDocuments={selectedDocuments}
                  setSelectedDocuments={setSelectedDocuments}
                  setClaimSubmitted={setClaimSubmitted}
                  claimsCount={relevantClaimsCount}
                />
              </Route>
              <Route exact path={VITOLO_STEP_DOCUMENT_DATE}>
                <SelectDocumentCreationDateVitolo />
              </Route>
              <Route exact path={VITOLO_STEP_TREATMENT_PRICE}>
                <TreatmentAmountVitolo />
              </Route>
              <Route exact path={VITOLO_STEP_CUSTOMER_NOTE}>
                <CustomerNoteVitoloWithUploadVitolo
                  selectedDocuments={selectedDocuments}
                  setSelectedDocuments={setSelectedDocuments}
                />
              </Route>

              <Route exact path={VITOLO_STEP_REVIEW}>
                <SubmitClaimFormVitolo
                  selectedDocuments={selectedDocuments}
                  setSelectedDocuments={setSelectedDocuments}
                  setClaimSubmitted={setClaimSubmitted}
                />
              </Route>

              <Route exact path={VITOLO_STEP_EXISTING_CLAIMS}>
                <SelectExistingClaimVitolo claims={relevantClaims} />
              </Route>
            </Switch>
          )}
        />
      </Container>
    </CSSTransition>
  );
};

export default NewVitoloClaim;
