import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { TabPanel, Tabs } from 'react-tabs';
import { FormattedMessage } from 'react-intl';

import { GET_CUSTOMER, PETOLO_REFERRAL_INFO_LINK } from '../../../../shared';

import PetoloReferralInformation from './PetoloReferralInformation';
import PetoloReferralStatus from './PetoloReferralStatus';
import { Container, PageTitle } from '../../../../components/common';
import {
  StyledReferralTab,
  StyledReferralTabList,
  StyledReferralTabPanel,
} from '../ReferralPage.styled';

function PetoloReferral() {
  const { data: customerData, loading: customerLoading } = useQuery(GET_CUSTOMER);
  const { uuid } = customerData?.customer || {};

  const [selectedTabIdx, setSelectedTabIdx] = useState(0);

  const handleSelectTab = (index) => {
    setSelectedTabIdx(index);
  };

  const handleGoToReferralInfoLink = () => {
    let referralInfoLink = PETOLO_REFERRAL_INFO_LINK;

    if (uuid) {
      referralInfoLink = `${PETOLO_REFERRAL_INFO_LINK}?uuid=${uuid}`;
    }

    window.open(referralInfoLink, '_blank');
  };

  return (
    <Container>
      <PageTitle style={{ fontSize: '1.875rem' }}>
        <FormattedMessage
          id="referral.page.petolo.title"
          values={{ amount: 50, couponbrand: 'Fressnapf-Gutschein' }}
        />
      </PageTitle>

      <Tabs
        selectedIndex={selectedTabIdx}
        selectedTabClassName="selected-referral-tab"
        onSelect={handleSelectTab}
      >
        <StyledReferralTabList>
          <StyledReferralTab>
            <FormattedMessage id="referral.page.tab.label.one" />
          </StyledReferralTab>
          <StyledReferralTab>
            <FormattedMessage id="referral.page.tab.label.two" />
          </StyledReferralTab>
        </StyledReferralTabList>

        <StyledReferralTabPanel>
          <PetoloReferralInformation
            customer={customerData?.customer}
            customerLoading={customerLoading}
            onReferralInfoLinkClick={handleGoToReferralInfoLink}
          />
        </StyledReferralTabPanel>

        <TabPanel>
          <PetoloReferralStatus onReferralInfoLinkClick={handleGoToReferralInfoLink} />
        </TabPanel>
      </Tabs>
    </Container>
  );
}

export default PetoloReferral;
