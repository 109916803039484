import React, { useState, useEffect, useRef } from 'react';
import { useQuery } from '@apollo/client';
import { Switch, Route, useLocation, useRouteMatch, useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import { useIntl, FormattedMessage } from 'react-intl';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import {
  LOCAL_SHOW_ONBOARDS,
  GET_CLAIMS,
  GET_CUSTMER_ASSIGNMENT_COUNT,
  USER_CLAIMS_ONBOARD_KEY,
  useReady,
  claimStatus,
  INSURANCE_TYPE_DENTOLO,
  INSURANCE_CATEGORY_DENTAL,
  INSURANCE_CATEGORY_PET_HEALTH,
  INSURANCE_CATEGORY_PET_LIABILITY,
  INSURANCE_CATEGORY_HEALTH,
} from '../shared';
import { useInsuranceInfo } from '../hooks/useInsuranceInfo';

import UserClaimDetails from '../components/UserClaimDetails';
import { ClaimStatus, Loading, Link } from './ui';
import { Container, PageTitle, Notification, Text } from './common';
import {
  StyledUserClaimsSlideGroupContainer,
  StyledNewReimbursementButtonContainer,
} from './styled/UserClaims.styled';

import {
  CLAIM_UPLOAD_FLOW_SOURCE_EXISTING_CLAIM_DOCUMENT,
  CLAIM_UPLOAD_FLOW_SOURCE_NEW_CLAIM_DOCUMENT,
} from '../features/claims/claimUploadFlowSource';
import {
  DENTOLO_STEP_EXISTING_CLAIMS,
  DENTOLO_STEP_MAIN,
  PETOLO_STEP_EXISTING_CLAIMS,
  PETOLO_STEP_MAIN,
  PET_LIABILITY_STEP_MAIN,
  VITOLO_STEP_MAIN,
  VITOLO_STEP_EXISTING_CLAIMS,
} from '../features/claims/new/newClaimsFormSteps';
import {
  relevantDentoloClaimsForList,
  relevantPetHealthClaimsForList,
} from '../features/claims/claimFormData/newClaimFormData';

// Least priority first
const claimsStatusPriority = [
  claimStatus.DECLINED,
  claimStatus.PARTIALLY_CLOSED,
  claimStatus.REIMBURSEMENT_ACKNOWLEDGED,
  claimStatus.CLOSED,
  claimStatus.WAITING_FOR_INFORMATION,
];

const sortClaimsByNewlyCreated = (claim1, claim2) => {
  const claim1CreatedAt = dayjs(claim1?.createdAt);
  const claim2CreatedAt = dayjs(claim2?.createdAt);
  if (claim1CreatedAt.isAfter(claim2CreatedAt)) {
    return -1;
  } else {
    return 1;
  }
};

const sortClaims = (claim1, claim2) => {
  if (claim1?.statusUpdated && !claim2?.statusUpdated) {
    return -1;
  } else if (!claim1?.statusUpdated && claim2?.statusUpdated) {
    return 1;
  } else if (!claim1?.statusUpdated && !claim2?.statusUpdated) {
    return sortClaimsByNewlyCreated(claim1, claim2);
  }

  // Status is updated for both of them
  const claim1StatusIndex = claimsStatusPriority.indexOf(claim1?.status);
  const claim2StatusIndex = claimsStatusPriority.indexOf(claim2?.status);
  if (claim1StatusIndex > claim2StatusIndex) {
    return -1;
  } else if (claim1StatusIndex < claim2StatusIndex) {
    return 1;
  } else {
    return sortClaimsByNewlyCreated(claim1, claim2);
  }
};

const getPageTitleId = (insuranceCategory) => {
  switch (insuranceCategory) {
    case INSURANCE_CATEGORY_PET_LIABILITY:
      return 'user_claims.pet_liability.page.title';
    default:
      return 'user_claims.page.title';
  }
};

const getPageDescriptionId = (insuranceCategory) => {
  switch (insuranceCategory) {
    case INSURANCE_CATEGORY_PET_LIABILITY:
      return 'user_claims.pet_liability.page.description';
    default:
      return 'user_claims.page.description';
  }
};

const getNewClaimDocumentButtonId = (insuranceCategory) => {
  switch (insuranceCategory) {
    case INSURANCE_CATEGORY_PET_LIABILITY:
      return 'user_claims.pet_liability.button.new_claim_report';
    default:
      return 'user_claims.button.new_claim_document';
  }
};

const getNewClaimURLPath = (insuranceCategory) => {
  switch (insuranceCategory) {
    case INSURANCE_CATEGORY_DENTAL:
      return `${DENTOLO_STEP_MAIN}?source=${CLAIM_UPLOAD_FLOW_SOURCE_NEW_CLAIM_DOCUMENT}`;

    case INSURANCE_CATEGORY_PET_HEALTH:
      return `${PETOLO_STEP_MAIN}?source=${CLAIM_UPLOAD_FLOW_SOURCE_NEW_CLAIM_DOCUMENT}`;

    case INSURANCE_CATEGORY_PET_LIABILITY:
      return `${PET_LIABILITY_STEP_MAIN}?source=${CLAIM_UPLOAD_FLOW_SOURCE_NEW_CLAIM_DOCUMENT}`;
    case INSURANCE_CATEGORY_HEALTH:
      return `${VITOLO_STEP_MAIN}?source=${CLAIM_UPLOAD_FLOW_SOURCE_NEW_CLAIM_DOCUMENT}`;
    default:
      return `${DENTOLO_STEP_MAIN}?source=${CLAIM_UPLOAD_FLOW_SOURCE_NEW_CLAIM_DOCUMENT}`;
  }
};

const getExistingClaimURLPath = (insuranceCategory) => {
  switch (insuranceCategory) {
    case INSURANCE_CATEGORY_DENTAL:
      return `${DENTOLO_STEP_EXISTING_CLAIMS}?source=${CLAIM_UPLOAD_FLOW_SOURCE_EXISTING_CLAIM_DOCUMENT}`;

    case INSURANCE_CATEGORY_PET_HEALTH:
      return `${PETOLO_STEP_EXISTING_CLAIMS}?source=${CLAIM_UPLOAD_FLOW_SOURCE_EXISTING_CLAIM_DOCUMENT}`;

    case INSURANCE_CATEGORY_PET_LIABILITY:
      return `${PET_LIABILITY_STEP_MAIN}?source=${CLAIM_UPLOAD_FLOW_SOURCE_EXISTING_CLAIM_DOCUMENT}`;
    case INSURANCE_CATEGORY_HEALTH:
      return `${VITOLO_STEP_EXISTING_CLAIMS}?source=${CLAIM_UPLOAD_FLOW_SOURCE_EXISTING_CLAIM_DOCUMENT}`;

    default:
      return `${DENTOLO_STEP_EXISTING_CLAIMS}?source=${CLAIM_UPLOAD_FLOW_SOURCE_EXISTING_CLAIM_DOCUMENT}`;
  }
};

const getRelevantClaimsForDocumentUpload = (insuranceCategory, claims) => {
  switch (insuranceCategory) {
    case INSURANCE_CATEGORY_DENTAL:
      return relevantDentoloClaimsForList(claims);

    case INSURANCE_CATEGORY_PET_HEALTH:
      return relevantPetHealthClaimsForList(claims);

    case INSURANCE_CATEGORY_PET_LIABILITY:
      return [];

    default:
      return relevantDentoloClaimsForList(claims);
  }
};

const UserClaims = () => {
  let history = useHistory();
  const intl = useIntl();

  const { path } = useRouteMatch();
  const location = useLocation();
  const ready = useReady();
  const [claims, setClaims] = useState([]);
  const [showUnassignedNotification, setShowUnassignedNotification] = useState(false);
  const [selectedClaim, setSelectedClaim] = useState(null);
  const [enterdElement, setEnteredElement] = useState(null);
  const itemsRefs = useRef([]);

  const getClaims = useQuery(GET_CLAIMS);
  const showOnboards = useQuery(LOCAL_SHOW_ONBOARDS);
  const attachRequest = useQuery(GET_CUSTMER_ASSIGNMENT_COUNT);
  const showOnboardsData = showOnboards?.data?.showOnboards;
  const showOnboardForUserClaimsData = Array.isArray(showOnboardsData)
    ? showOnboardsData.find((item) => item?.name === USER_CLAIMS_ONBOARD_KEY)
    : null;
  const showOnboardForUserClaims = showOnboardForUserClaimsData?.show;
  const isUserClaimPage = location?.pathname === '/user-claims-info';
  const animationTimeout = { enter: 500, exit: 10 };

  const { type: insuranceType, category: insuranceCategory } = useInsuranceInfo();
  const isDentolo = insuranceType === INSURANCE_TYPE_DENTOLO;
  const newClaimPath = getNewClaimURLPath(insuranceCategory);
  const existingClaimPath = getExistingClaimURLPath(insuranceCategory);

  const relevantClaimsForDocumentUpload = getRelevantClaimsForDocumentUpload(
    insuranceCategory,
    getClaims?.data?.claims || []
  );
  const hasRelevantClaimsForDocumentUpload =
    Array.isArray(relevantClaimsForDocumentUpload) && relevantClaimsForDocumentUpload.length > 0;

  useEffect(() => {
    let timerFunc;

    if (isUserClaimPage && selectedClaim) {
      timerFunc = setTimeout(() => {
        setSelectedClaim(null);
      }, 500);
    }

    return () => clearTimeout(timerFunc);
  }, [location, selectedClaim, isUserClaimPage]);

  useEffect(() => {
    const showOnBoardForUserClaimsLocalStorageToken =
      window.localStorage.getItem(USER_CLAIMS_ONBOARD_KEY);
    if (showOnboardForUserClaims || showOnBoardForUserClaimsLocalStorageToken !== 'skip') {
      if (isDentolo) {
        history.push('/user-claims/onboarding-dentolo/1');
      }
    }
  }, [showOnboardForUserClaims, history, isDentolo]);

  useEffect(() => {
    const attachCount = attachRequest?.data?.customer?.attachmentsPendingCount;
    if (!attachRequest?.loading && attachRequest?.data) {
      setShowUnassignedNotification(attachCount > 0);
    }
  }, [attachRequest, setShowUnassignedNotification]);

  useEffect(() => {
    if (!getClaims?.data?.claims) return;

    const claimsData = Array.isArray(getClaims?.data?.claims) ? getClaims.data.claims : [];

    let sortedClaims = [...claimsData];
    sortedClaims.sort(sortClaims);

    setClaims(sortedClaims);
  }, [getClaims]);

  const pageTitleId = getPageTitleId(insuranceCategory);
  const pageDescriptionId = getPageDescriptionId(insuranceCategory);
  const newClaimBtnLabelId = getNewClaimDocumentButtonId(insuranceCategory);

  return (
    <Container>
      {getClaims?.loading && <Loading showLogo={true} />}

      {!getClaims?.loading && (
        <StyledUserClaimsSlideGroupContainer>
          {showUnassignedNotification && isUserClaimPage && (
            <div style={{ marginTop: '-2rem', marginBottom: '1.5rem' }}>
              <Notification
                type="success"
                title={intl.formatMessage({ id: 'policy.info.document.notification.submitted' })}
                message={intl.formatMessage({
                  id: 'policy.info.document.notification.description',
                })}
              />
            </div>
          )}

          <Route
            render={({ location }) => {
              return (
                <TransitionGroup component={null}>
                  <CSSTransition
                    timeout={300}
                    classNames="slide-in"
                    key={location.key}
                    onEntered={(e) => setEnteredElement(e)}
                    onExit={(_) => setEnteredElement(null)}
                  >
                    <Switch location={location}>
                      <Route exact path={path}>
                        <section className="slide-in slide-in-list">
                          <CSSTransition
                            in={ready && isUserClaimPage}
                            timeout={300}
                            classNames="fade"
                            unmountOnExit
                          >
                            <PageTitle>
                              <FormattedMessage id={pageTitleId} />
                            </PageTitle>
                          </CSSTransition>

                          <CSSTransition
                            in={ready && isUserClaimPage}
                            timeout={animationTimeout}
                            classNames="fade"
                            unmountOnExit
                          >
                            <Text style={{ marginBottom: '1.5rem' }}>
                              <FormattedMessage id={pageDescriptionId} />
                            </Text>
                          </CSSTransition>

                          <CSSTransition
                            in={ready}
                            timeout={animationTimeout}
                            classNames="fade"
                            unmountOnExit
                          >
                            <>
                              <StyledNewReimbursementButtonContainer>
                                <Link to={newClaimPath}>
                                  <FormattedMessage id={newClaimBtnLabelId} />
                                </Link>
                              </StyledNewReimbursementButtonContainer>

                              <StyledNewReimbursementButtonContainer>
                                <Link
                                  variant="outline"
                                  to={existingClaimPath}
                                  disabled={!hasRelevantClaimsForDocumentUpload}
                                >
                                  <FormattedMessage id="user_claims.button.existing_claim_document" />
                                </Link>
                              </StyledNewReimbursementButtonContainer>
                            </>
                          </CSSTransition>

                          <TransitionGroup>
                            {claims
                              .filter((claim) => claim?.status !== claimStatus.CANCELLED)
                              .map((claim) => (
                                <CSSTransition
                                  key={claim.key}
                                  timeout={400}
                                  classNames="fade-in"
                                  unmountOnExit
                                >
                                  <div
                                    ref={(el) => (itemsRefs.current[claim.key] = el)}
                                    tabIndex={-1}
                                  >
                                    <ClaimStatus
                                      claim={claim}
                                      setSelectedClaim={setSelectedClaim}
                                    />
                                  </div>
                                </CSSTransition>
                              ))}
                          </TransitionGroup>
                        </section>
                      </Route>

                      <Route exact path={`${path}/:id`}>
                        <section className="slide-in slide-in-details">
                          <UserClaimDetails
                            selectedClaim={selectedClaim}
                            enterdElement={enterdElement}
                          />
                        </section>
                      </Route>
                    </Switch>
                  </CSSTransition>
                </TransitionGroup>
              );
            }}
          />
        </StyledUserClaimsSlideGroupContainer>
      )}
    </Container>
  );
};

export default UserClaims;
