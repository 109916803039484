import styled from 'styled-components';
import { color } from '../../theme/functions';
import { min_width_md } from '../../theme/breakpoints';

export const StyledCatATBDocumentDetails = styled.div`
  a {
    position: relative;
    display: inline-block;
    color: ${color('main_blue')};
    text-decoration: none;
    transition: color 0.2s ease-out;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      height: 2px;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      background-color: ${color('main_blue')};
      transform-origin: bottom left;
      transition: transform 0.2s ease-out;
    }

    &:hover {
      color: ${color('professional_light_blue')};

      &::after {
        transform: scaleX(0);
        transform-origin: bottom right;
      }
    }

    &:active,
    &:focus {
      color: ${color('professional_light_blue')};

      &::after {
        background-color: ${color('professional_light_blue')};
        transform: scaleX(1);
        transform-origin: bottom left;
      }
    }
  }
`;

export const StyledButtonContainer = styled.div`
  margin-top: 1.5rem;

  > button {
    width: 100%;
  }

  @media (min-width: ${min_width_md}) {
    > button {
      margin-left: auto;
      margin-right: auto;
      min-width: 21.375rem;
      width: fit-content;
    }
  }
`;

export const StyledIconContainer = styled.div`
  margin-bottom: 1rem;
`;
