import styled from 'styled-components';
import { color } from '../../../theme/functions';
import { THEME_PETOLO } from '../../../shared';
import { Button } from '../Button';
import Slider from 'react-slick';

import { min_width_md, min_width_lg } from '../../../theme/breakpoints';

export const StyledOnboardWrapper = styled.div`
  display: flex;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: ${({ theme: { name } }) =>
    color(name === THEME_PETOLO ? 'ultra_light_gray' : 'ultra_clear_light_blue')};
  z-index: 60;
  overflow: auto;
  padding-top: 48px;
  @media (min-width: ${min_width_md}) {
    padding-top: 40px;
  }
`;
export const StyledSlidesWrapper = styled.div`
  height: auto;
  padding: 0;
  position: relative;
  width: 100%;
  min-height: 1px;
  @media (min-width: ${min_width_md}) {
    height: 100%;
    flex: 0 0 83.333333%;
    margin-right: 8.333333%;
    margin-left: 8.333333%;
    max-width: 83.333333%;
  }
`;
export const StyledOnboardRow = styled.div`
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  @media (min-width: ${min_width_md}) {
    height: auto;
  }
`;
export const StyledOnboardContainer = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 0;
  max-width: auto;
  @media (min-width: ${min_width_md}) {
    max-width: 960px;
  }
`;
export const StyledOnBoardingButton = styled(Button)`
  cursor: pointer;
  position: relative;
  margin: auto;
  width: auto;
  border: none;
  border: ${({ last }) => (last ? 'none' : '2px solid ')};
  border-color: ${color('main_blue')};

  @media (min-width: ${min_width_md}) {
    top: 39.375rem;
    bottom: unset;
    bottom: auto;
    right: 0.9375rem;
    margin: 0;
    width: -webkit-max-content;
    position: absolute;
    border-radius: 7px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const StyledBoardingSlider = styled(Slider)`
  .slick-slider {
    height: 100%;
    margin-bottom: 3em;
  }

  .slick-slide {
    box-shadow: none;

    & > div {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      height: calc(100% - 7.5rem);

      & > div {
        height: 100%;
      }
    }
  }

  .slick-track,
  .slick-list {
    height: 100%;
  }

  .slick-dots {
    list-style: none;
    position: absolute;
    bottom: -4rem;
    left: -18px;
    right: 0;
    width: 100%;
    text-align: center;

    li {
      display: inline-block;
      margin-right: 0.5rem;

      button {
        font-size: 0;
        line-height: 0;
        appearance: none;
        border: none;
        background-color: transparent;

        &:before {
          content: '';
          width: 0.5rem;
          height: 0.5rem;
          display: inline-block;
          border-radius: 100%;
          border: 0.0625rem solid ${color('main_blue')};
        }
      }

      &.slick-active {
        button {
          &:before {
            background-color: ${color('main_blue')};
          }
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .slick-arrow {
    position: absolute;
    bottom: -3.5rem;
    width: 2.25rem;
    height: 2.25rem;
    padding: 0.5rem;
    font-size: 0;
    border: none;
    background-color: transparent;
    box-shadow: none;
    cursor: pointer;
    z-index: 2;

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      display: inline-block;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      background-color: transparent;
    }
  }

  .slick-prev {
    left: 1rem;

    &:before {
      background-image: url('/images/user-area-icons/general/icon.caret.left.svg');
    }
  }

  .slick-next {
    right: 1rem;

    &:before {
      background-image: url('/images/user-area-icons/general/icon.caret.right.svg');
    }
  }

  .slick-disabled {
    opacity: 0;
  }

  @media (min-width: ${min_width_md}) {
    .slick-slide {
      & > div {
        height: calc(100% - 10rem);
      }
    }

    .slick-prev {
      left: 0rem;
    }

    .slick-next {
      right: 0rem;
    }

    .slick-dots {
      li {
        margin-right: 0.875rem;

        button {
          &:before {
            width: 0.875rem;
            height: 0.875rem;
            border: 0.125rem solid ${color('main_blue')};
          }
        }
      }
    }
  }

  @media (min-width: ${min_width_lg}) {
    .slick-slider {
      margin: auto;
    }

    .slick-slide {
      & > div {
        max-height: 32.75rem;
      }
    }

    .slick-dots {
      top: 33rem;
      bottom: unset;
      bottom: auto;
      position: absolute;
    }

    .slick-arrow {
      top: 14.6rem;
      bottom: unset;
      bottom: auto;
      position: absolute;
    }

    .slick-prev {
      left: 0;
    }

    .slick-next {
      right: 0;
    }
  }
`;
