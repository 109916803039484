import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { imgLinks, DENTOLO_HELP_LINK } from '../../shared';
import { min_width_md } from '../../theme/breakpoints';

import { Button, Paragraph } from '../ui';
import { StyledButtonContainer } from '../ui/styled/Button.styled';
import { Modal, Text } from '../common';
import { useLocalStorage } from '../../hooks/useLocalStorage';

const StyledTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1.5rem;

  > img {
    width: 3.75rem;
    height: 3.75rem;
  }

  > p {
    margin-left: 0.5rem;
  }

  @media (min-width: ${min_width_md}) {
    > img {
      width: 5rem;
      height: 5rem;
    }

    > p {
      margin-left: 1rem;
    }
  }
`;

const DentistsMapOnboard = ({ onboard, saveOnboardClose }) => {
  const [getFromLocalStorage] = useLocalStorage();

  const gotoHelpCenter = (ev) => {
    ev.preventDefault();

    const gduidFromLocalStorage = getFromLocalStorage('gduid');
    const linkToUse = gduidFromLocalStorage
      ? `${DENTOLO_HELP_LINK}?gduid=c5u4anvImmoPS8N5TK6rRSyl3Cg9He7I`
      : DENTOLO_HELP_LINK;

    window.open(linkToUse, '_self');
  };

  return (
    <Modal isOpen={onboard} onClose={saveOnboardClose} showClose>
      <StyledTitle>
        <img src={imgLinks['icon.female.dentist']} alt="female dentist" />

        <Text fontSize="1.25rem" fontWeight="700">
          Über das dentolo Netzwerk
        </Text>
      </StyledTitle>

      <Paragraph>
        Hier finden Sie eine Karte aller dentolo Zahnärzte bei denen Sie als dentolo Kunde eine
        höhere Kostenerstattung bekommen.
      </Paragraph>

      <Paragraph>
        Am besten vereinbaren Sie gleich einen Termin bei einem Zahnarzt oder Zahnärztin in Ihrer
        Nähe. Bei Fragen rund um die dentolo Zahnärzte finden Sie ausführliche Informationen in
        unserem <button onClick={gotoHelpCenter}>Hilfecenter</button>.
      </Paragraph>

      <StyledButtonContainer>
        <Button variant="outline" onClick={() => saveOnboardClose()} aria-label="Close Onboard">
          <FormattedMessage id="common.button.close" />
        </Button>
      </StyledButtonContainer>
    </Modal>
  );
};

DentistsMapOnboard.propTypes = {
  onboard: PropTypes.bool,
  saveOnboardClose: PropTypes.func,
};

export default DentistsMapOnboard;
