import colors from './colors';

const boxShadows = {
  box_shadow_1: '0px 0px 0.625rem rgba(0, 0, 0, 0.1)',
  box_shadow_2: '0px 0.125 0.375rem rgba(0, 0, 0, 0.1)',
  box_shadow_3: `0px 0px 0px 3px ${colors.professional_light_blue}`,
  box_shadow_4: `0px 0px 0px 3px ${colors.vibrant_light_violet}`,
};

export default boxShadows;
