import styled from 'styled-components';

import { color } from '../../../theme/functions';
import { min_width_md } from '../../../theme/breakpoints';
import { promotionCarouselSliderHeight } from '../PromotionCarousel/PromotionCarousel.styled';

import { Href } from '../../../components/ui';

export const StyledVitoloBetterDocCard = styled.div`
  display: flex;
  background: ${color('periwinkle')};
`;

export const StyledVitoloBetterDocCardImageContainer = styled.div`
  margin-right: 1rem;
`;

export const StyledVitoloBetterDocCardImageMobile = styled.img`
  display: block !important;
  height: ${promotionCarouselSliderHeight.mobile};
  position: relative;
  right: 10px;
  @media (min-width: ${min_width_md}) {
    display: none !important;
  }
`;
export const StyledVitoloBetterDocCardImageDesktop = styled.img`
  display: none !important;
  height: 25.75rem;
  position: relative;
  bottom: 84px;
  @media (min-width: ${min_width_md}) {
    display: block !important;
  }
`;

export const StyledVitoloBetterDocCardTitle = styled.div`
  width: 100%;
  font-size: 1rem;
  font-weight: 700;
  color: ${color('main_blue')};
  margin-top: 0.5rem;

  @media (min-width: ${min_width_md}) {
    width: 355px;
    font-size: 1.25rem;
    margin-top: 1rem;
  }
`;

export const StyledVitoloBetterDocCardText = styled.div`
  width: 210px;
  font-size: 1rem;
  color: ${color('main_blue')};
  margin-top: 0;

  @media (min-width: ${min_width_md}) {
    width: 340px;
    font-size: 1.25rem;
  }
`;
export const StyledVitoloBetterDocCardButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
export const StyledVitoloBetterDocCardButton = styled(Href)`
  margin-top: 1rem;
  padding: none;
  color: ${color('white')};
  text-decoration: none;
  background-color: ${color('main_blue')};
  @media (min-width: ${min_width_md}) {
    padding: 0rem 4rem;
  }
`;
