import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import PropTypes from 'prop-types';

import { useInsuranceInfo } from '../../hooks/useInsuranceInfo';
import {
  useSelectCustomer,
  INSURANCE_TYPE_PETOLO,
  INSURANCE_TYPE_DENTOLO,
  INSURANCE_CATEGORY_PET_HEALTH,
  INSURANCE_CATEGORY_PET_LIABILITY,
  DA_DIREKT_POLICY_CATEGORIES,
  INSURANCE_TYPE_VITOLO,
  RISK_CARRIER_AMTRUST,
  SPECIAL_TARIFF_POLICY_CATEGORIES,
} from '../../shared';
import {
  myProfileLinkItems,
  miscellaneousLinkItems,
  petHelpLinkItems,
  vitoloHelpLinkItems,
  daDirektReferralLinkDental,
  daDirektReferralLinkPet,
} from './navMenuConfig';
import {
  getInsuredPersonName,
  getNameAccordingToInsuranceCategory,
} from '../../utils/customerUtils';

import NavMenuLink from './NavMenuLink';
import NavMenuMobileSelectUserLink from './NavMenuMobileSelectUserLink';
import NavMenuMobileUserInfo from './NavMenuMobileUserInfo';
import NavMenuSignOutLink from './NavMenuSignOutLink';
import NavMenuAccordionItem from './NavMenuAccordionItem';
import {
  StyledNavMenuMobileContainer,
  StyledNavMenuMobileOverlay,
  StyledSignoutButtonContainer,
  StyledUserLogo,
} from './styled/NavMenuMobile.styled';

function NavMenuMobile(props) {
  const { showNavMenu, customer, customers, toggleNavMenu, customerSelect } = props;

  const tabIndex = showNavMenu ? 0 : -1;
  const location = useLocation();
  const history = useHistory();

  const urlValues = queryString.parse(location.search);
  const showInner = !useSelectCustomer(location.pathname) && !urlValues.auth;

  const isOnlyCustomer = customers?.length === 1;
  const textForUserLogo = (customer) =>
    `${customer?.firstName?.charAt(0)}${customer?.lastName?.charAt(0)}`.toUpperCase();
  const inactiveContractPlaceholder = <FormattedMessage id="contract.placeholder.inactive" />;
  const getCustomerDescription = (customer) =>
    customer?.endedContract === 'true' ? inactiveContractPlaceholder : customer?.policyName || '';

  const { type: insuranceType, category: insCategory } = useInsuranceInfo();
  const isDentolo = insuranceType === INSURANCE_TYPE_DENTOLO;
  const isPetolo = insuranceType === INSURANCE_TYPE_PETOLO;
  const isVitolo = insuranceType === INSURANCE_TYPE_VITOLO;

  const customerPolicyCategory = customer?.contract?.policyCategory;

  const isAmtrustCustomer = customer?.contract?.riskCarrier === RISK_CARRIER_AMTRUST;
  const isPetHealth = customer?.insuranceCategory === INSURANCE_CATEGORY_PET_HEALTH;
  const isPetLiability = customer?.insuranceCategory === INSURANCE_CATEGORY_PET_LIABILITY;

  const isDaDirektCustomer =
    Number.isInteger(customerPolicyCategory) &&
    DA_DIREKT_POLICY_CATEGORIES.includes(customerPolicyCategory);

  const isSpecialTariffCustomer =
    Number.isInteger(customerPolicyCategory) &&
    SPECIAL_TARIFF_POLICY_CATEGORIES.includes(customerPolicyCategory);

  const gotoAndToggleNav = (to) => {
    if (!to) return;

    history.push(to);
    toggleNavMenu();
  };

  const getClaimsMenuLabelId = () => {
    switch (insCategory) {
      case INSURANCE_CATEGORY_PET_LIABILITY:
        return 'menu.header.item.claim_report';

      default:
        return 'menu.header.item.reimbursement';
    }
  };

  const handleExternalLinkClick = (ev, link) => {
    if (ev) ev.preventDefault();

    if (!link) return;

    window.open(link, '_blank');
  };

  return (
    <StyledNavMenuMobileOverlay open={showNavMenu} insuranceType={insuranceType}>
      {showInner && (
        <StyledNavMenuMobileContainer userAccordion>
          <NavMenuAccordionItem
            title={
              <NavMenuMobileUserInfo
                name={getInsuredPersonName(customer)}
                policyName={getCustomerDescription(customer)}
              />
            }
            collapsible={!isOnlyCustomer}
            customLogo={
              isOnlyCustomer ? <StyledUserLogo>{textForUserLogo(customer)}</StyledUserLogo> : null
            }
            tabIndex={tabIndex}
            type="user"
          >
            <ul>
              {customers?.map((customerItem) => {
                const { key, policyName, insuranceCategory, endedContract } = customerItem;
                const isContractEnded = endedContract === 'true';
                return (
                  <NavMenuMobileSelectUserLink
                    key={key}
                    isContractEnded={isContractEnded}
                    policyName={isContractEnded ? inactiveContractPlaceholder : policyName}
                    isCurrentCustomer={customer?.key === key}
                    insuranceCategory={insuranceCategory}
                    customerName={getNameAccordingToInsuranceCategory(customerItem)}
                    tabIndex={tabIndex}
                    onClick={() => customerSelect(customerItem?.uuid)}
                  />
                );
              })}
            </ul>
          </NavMenuAccordionItem>

          <NavMenuAccordionItem
            title={<FormattedMessage id={`menu.header.item.my_profile`} />}
            tabIndex={tabIndex}
          >
            <ul>
              {myProfileLinkItems.map((profileLinkItem) => {
                const { link, text, icon, externalUrl, type } = profileLinkItem;

                if (!!type && type !== insuranceType) return null;

                return (
                  <NavMenuLink
                    key={link}
                    text={text}
                    link={link}
                    icon={icon}
                    externalUrl={externalUrl}
                    tabIndex={tabIndex}
                    onClick={() => toggleNavMenu()}
                    insuranceType={insuranceType}
                  />
                );
              })}
            </ul>
          </NavMenuAccordionItem>

          <NavMenuAccordionItem
            title={<FormattedMessage id={getClaimsMenuLabelId()} />}
            tabIndex={tabIndex}
            collapsible={false}
            active={location?.pathname === '/user-claims-info'}
            onTitleClick={() => gotoAndToggleNav('/user-claims-info')}
          />

          {isDentolo && (
            <>
              <NavMenuAccordionItem
                title={<FormattedMessage id={`menu.header.item.find_dentist`} />}
                tabIndex={tabIndex}
                collapsible={false}
                active={location?.pathname === '/dentists-map'}
                onTitleClick={() => gotoAndToggleNav('/dentists-map')}
              />

              {!isDaDirektCustomer && !isAmtrustCustomer && (
                <NavMenuAccordionItem
                  title={<FormattedMessage id={`menu.header.item.referrals`} />}
                  tabIndex={tabIndex}
                  collapsible={false}
                  active={location?.pathname === '/referral'}
                  onTitleClick={() => gotoAndToggleNav('/referral')}
                />
              )}

              {isDaDirektCustomer && (
                <NavMenuAccordionItem
                  title={<FormattedMessage id={`menu.header.item.referrals`} />}
                  tabIndex={tabIndex}
                  collapsible={false}
                  externalLink={true}
                  onTitleClick={(ev) =>
                    handleExternalLinkClick(ev, daDirektReferralLinkDental?.link)
                  }
                />
              )}

              <NavMenuAccordionItem
                title={<FormattedMessage id={`menu.header.item.help`} />}
                tabIndex={tabIndex}
              >
                <ul>
                  {miscellaneousLinkItems.map((miscLinkItem) => {
                    const { link, text, icon, externalUrl } = miscLinkItem;
                    return (
                      <NavMenuLink
                        key={link}
                        text={text}
                        link={link}
                        icon={icon}
                        externalUrl={externalUrl}
                        tabIndex={tabIndex}
                        onClick={() => toggleNavMenu()}
                        insuranceType={insuranceType}
                      />
                    );
                  })}
                </ul>
              </NavMenuAccordionItem>
            </>
          )}

          {isPetolo && (
            <>
              {isPetHealth && (
                <NavMenuAccordionItem
                  title={<FormattedMessage id={`menu.header.item.find_vet`} />}
                  tabIndex={tabIndex}
                  collapsible={false}
                  active={location?.pathname === '/online-vets'}
                  onTitleClick={() => gotoAndToggleNav('/online-vets')}
                />
              )}

              {!isDaDirektCustomer && !isSpecialTariffCustomer && !isPetLiability && (
                <NavMenuAccordionItem
                  title={<FormattedMessage id={`menu.header.item.referrals`} />}
                  tabIndex={tabIndex}
                  collapsible={false}
                  active={location?.pathname === '/referral'}
                  onTitleClick={() => gotoAndToggleNav('/referral')}
                />
              )}

              {isDaDirektCustomer && (
                <NavMenuAccordionItem
                  title={<FormattedMessage id={`menu.header.item.referrals`} />}
                  tabIndex={tabIndex}
                  collapsible={false}
                  externalLink={true}
                  onTitleClick={(ev) => handleExternalLinkClick(ev, daDirektReferralLinkPet?.link)}
                />
              )}

              <NavMenuAccordionItem
                title={<FormattedMessage id={`menu.header.item.help`} />}
                tabIndex={tabIndex}
              >
                <ul>
                  {petHelpLinkItems.map((miscLinkItem) => {
                    const { link, text, icon, externalUrl } = miscLinkItem;
                    return (
                      <NavMenuLink
                        key={link}
                        text={text}
                        link={link}
                        icon={icon}
                        externalUrl={externalUrl}
                        tabIndex={tabIndex}
                        onClick={() => toggleNavMenu()}
                        insuranceType={insuranceType}
                      />
                    );
                  })}
                </ul>
              </NavMenuAccordionItem>
            </>
          )}

          {isVitolo && (
            <NavMenuAccordionItem
              title={<FormattedMessage id={`menu.header.item.help`} />}
              tabIndex={tabIndex}
            >
              <ul>
                {vitoloHelpLinkItems.map((miscLinkItem) => {
                  const { link, text, icon, externalUrl } = miscLinkItem;
                  return (
                    <NavMenuLink
                      key={link}
                      text={text}
                      link={link}
                      icon={icon}
                      externalUrl={externalUrl}
                      tabIndex={tabIndex}
                      onClick={() => toggleNavMenu()}
                      insuranceType={insuranceType}
                    />
                  );
                })}
              </ul>
            </NavMenuAccordionItem>
          )}

          <StyledSignoutButtonContainer>
            <NavMenuSignOutLink tabIndex={tabIndex} />
          </StyledSignoutButtonContainer>
        </StyledNavMenuMobileContainer>
      )}
    </StyledNavMenuMobileOverlay>
  );
}

export default NavMenuMobile;

NavMenuMobile.defaultProps = {
  showNavMenu: false,
  customers: [],
  customer: null,
};

NavMenuMobile.propTypes = {
  showNavMenu: PropTypes.bool,
  customers: PropTypes.array,
  customer: PropTypes.object,
  toggleNavMenu: PropTypes.func,
  customerSelect: PropTypes.func,
};
