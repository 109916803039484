import React, { useState, useEffect, useCallback } from 'react';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import PropTypes from 'prop-types';

import { switchCustomer } from '../../shared';
import { useTrackDtEvent, trackPageVisit } from '../../DtlTracker';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { min_width_md } from '../../theme/breakpoints';
import {
  TOGGLE_NAV_MENU_MUTATION,
  LOCAL_NAV_MENU,
  GET_CUSTOMERS,
  GET_CUSTOMER,
} from '../../shared';

import HeaderBurgerMenu from './HeaderBurgerMenu';
import HeaderLogoLinks from './HeaderLogoLinks';
import MaintenanceBanner from './MaintenanceBanner';
import { NavMenuSelectUserPopup, NavMenuDesktop, NavMenuMobile } from '../NavMenu';
import { StyledHeader, StyledHeaderContainer, StyledHeaderSticky } from './Header.styled';

function HeaderWithActions({ location, history }) {
  const [previousUuid, setPreviousUuid] = useState(null);
  const [previousUrl, setPreviousUrl] = useState('');
  const [hasBurger, setHasBurger] = useState(false);

  const [sendUserData] = useTrackDtEvent();
  const client = useApolloClient();

  const navMenu = useQuery(LOCAL_NAV_MENU);
  const showNavMenu = navMenu?.data?.showNavMenu;
  const [toggleNavMenu] = useMutation(TOGGLE_NAV_MENU_MUTATION);

  const switchCustomerToUuid = switchCustomer(client, history);

  const isDesktop = useMediaQuery(`(min-width: ${min_width_md})`);

  const {
    data: customersData,
    error: customersError,
    loading: isCustomersLoading,
  } = useQuery(GET_CUSTOMERS);

  const customers = customersData?.customers;

  const { data: customerData, loading: isCustomerLoading } = useQuery(GET_CUSTOMER);

  const currentCustomer = customerData?.customer;

  const params = queryString.parse(location?.search);
  const customerSourceZurich = params.customer_source === 'zurich';
  const riskCarrierZurich = currentCustomer?.contract?.riskCarrier === 'zurich';
  const showDaDirektImg = customerSourceZurich || riskCarrierZurich;

  const showMenu = currentCustomer && customers?.length;

  const onCustomerSelect = useCallback(
    (uuid) => {
      if (uuid) {
        switchCustomerToUuid(uuid).then((_) => {
          showNavMenu && toggleNavMenu();
        });
      }
    },
    [toggleNavMenu, switchCustomerToUuid, showNavMenu]
  );

  const trackDtEvent =
    (eventType = '', customer) =>
    () => {
      const trackData = {
        userData: {
          eventType: eventType,
          time: new Date().toISOString(),
          url: window?.location?.href,
          uuid: customer?.uuid,
        },
      };
      sendUserData(trackData);
    };

  useEffect(() => {
    const showBurgerMenu = !(!customers || customersError || isCustomersLoading);
    setHasBurger(showBurgerMenu);
  }, [customers, customersError, isCustomersLoading]);

  useEffect(() => {
    const elBody = document.body;

    if (showNavMenu && !isDesktop) {
      elBody.classList.add('no-scroll');
    } else {
      elBody.classList.remove('no-scroll');
    }

    return () => {
      elBody.classList.remove('no-scroll');
    };
  }, [isDesktop, showNavMenu]);

  useEffect(() => {
    const currentUrl = `${window.location.origin}${location.pathname}${location.search}${location.hash}`;
    const uuid = currentCustomer?.uuid;

    if (currentUrl !== previousUrl || (!!uuid && !!previousUuid && uuid !== previousUuid)) {
      if (!!uuid) {
        trackPageVisit(uuid, previousUrl);
        setPreviousUrl(currentUrl);
        setPreviousUuid(uuid);
      } else {
        if (!isCustomersLoading) {
          if (Array.isArray(customers) && customers.length === 1) {
            const uuidOfOnlyCustomer = customers[0]?.uuid;
            trackPageVisit(uuidOfOnlyCustomer, previousUrl);
            setPreviousUrl(currentUrl);
          } else {
            // Don't send any uuid info if there are multiple customers related to one account
            // becasue it's difficult to process it in the data warehouse
            trackPageVisit(uuid, previousUrl);
            setPreviousUrl(currentUrl);
          }
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, isCustomersLoading, currentCustomer]);

  return (
    <>
      <StyledHeader>
        <StyledHeaderSticky data-header-menu>
          <StyledHeaderContainer>
            <HeaderLogoLinks
              customer={currentCustomer}
              trackDtEvent={trackDtEvent}
              showDaDirektImg={showDaDirektImg}
            />

            {hasBurger && (
              <HeaderBurgerMenu
                isCustomerLoading={isCustomerLoading}
                isOpen={showNavMenu}
                toggleNavMenu={toggleNavMenu}
              />
            )}

            {showMenu && (
              <NavMenuSelectUserPopup
                customer={currentCustomer}
                customers={customers}
                isCustomerLoading={isCustomerLoading}
                trackDtEvent={trackDtEvent}
                customerSelect={onCustomerSelect}
              />
            )}
          </StyledHeaderContainer>
        </StyledHeaderSticky>
      </StyledHeader>

      <NavMenuMobile
        showNavMenu={showNavMenu}
        customer={currentCustomer}
        customers={customers}
        toggleNavMenu={toggleNavMenu}
        customerSelect={onCustomerSelect}
      />

      {showMenu && <NavMenuDesktop />}

      <MaintenanceBanner />
    </>
  );
}

HeaderWithActions.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(HeaderWithActions);
