import styled from 'styled-components';

import { min_width_md } from '../../../theme/breakpoints';
import { color, boxShadow } from '../../../theme/functions';
import { THEME_DENTOLO, THEME_PETOLO, THEME_VITOLO } from '../../../shared';

const boxShadowMap = {
  [THEME_DENTOLO]: boxShadow('box_shadow_3'),
  [THEME_PETOLO]: boxShadow('box_shadow_3'),
  [THEME_VITOLO]: boxShadow('primary'),
};

export const StyledDropdownSearch = styled.div`
  .dropdown-search {
    position: relative;
    width: 100%;

    &__control {
      border-radius: 5px;
      height: 3rem;
      border: 1px solid ${color('main_blue')};

      &--is-focused {
        box-shadow: ${({ theme }) => boxShadowMap[theme?.name] || boxShadow('box_shadow_3')};
      }
    }

    &__menu {
      margin: 2px 0 0;
      border-radius: 0;
    }

    &__menu-notice--no-options,
    &__menu-notice--loading {
      color: ${color('ultra_clear_light_blue')};
    }

    &__option {
      display: flex;
      align-items: center;
      padding-left: 1.5rem;
      min-height: 3rem;
      color: ${color('main_blue')};

      &--is-selected {
        background-color: ${color('white')} !important;
      }

      &--is-focused {
        background-color: ${color('ultra_clear_light_blue')} !important;
      }
    }

    &__placeholder {
      color: ${color('main_blue')};
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &__input {
      font-size: 1.25rem;
      color: ${color('main_blue')};

      > input {
        &:focus {
          box-shadow: none;
        }
      }
    }

    &__single-value {
      color: ${color('main_blue')};
    }

    &__value-container {
      padding-left: 1rem;
    }

    &__indicator-separator {
      display: none;
    }

    &__indicator {
      > svg {
        width: 1.7rem;
        height: 1.7rem;
        stroke: transparent;
        stroke: ${color('white')};
        stroke-width: 1px;
        fill: ${color('main_blue')};
      }
    }

    @media (min-width: ${min_width_md}) {
      &__control {
        height: 3.5rem;
      }
    }
  }
`;
