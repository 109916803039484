import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import ReactMarkdown from 'react-markdown';
import { useMutation } from '@apollo/client';

import { Container, PageTitle, Paragraph } from '../../components/common';
import { OpenLinkIcon } from '../../components/common/Icon';
import { Button } from '../../components/ui';
import { DECLINE_CAT_ATB_MIGRATION } from '../../shared';

import { StyledButtonContainer, StyledCatATBDocumentDetails } from './DeclineCatATBUpgrade.styled';

export default function DeclineCatATBUpgrade() {
  const intl = useIntl();
  const history = useHistory();

  const [declineCatATBMigration] = useMutation(DECLINE_CAT_ATB_MIGRATION);

  const [declining, setDeclining] = useState(false);

  const handleSubmit = async () => {
    setDeclining(true);

    try {
      const res = await declineCatATBMigration();
      setDeclining(false);

      const success = res?.data?.declineCatAtbMigration?.status === 'success';

      if (success) {
        history.push(`/decline-cat-atb-upgrade/success`);
      } else {
        throw new Error();
      }
    } catch (err) {
      history.push('/error');
    }
  };

  return (
    <Container>
      <PageTitle>
        <FormattedMessage id="decline_cat_atb_upgrade.title" />
      </PageTitle>

      <Paragraph>
        <FormattedMessage id="decline_cat_atb_upgrade.description.paragraph_1" />
        <FormattedMessage id="decline_cat_atb_upgrade.description.paragraph_2" />
      </Paragraph>

      <Paragraph>
        <ul>
          <li>
            <StyledCatATBDocumentDetails>
              <ReactMarkdown linkTarget="_blank" components={{ p: 'span' }}>
                {intl.formatMessage({
                  id: 'decline_cat_atb_upgrade.description.previous_document',
                })}
              </ReactMarkdown>
              &nbsp;
              <Link to="/cat_atb/old.pdf" target="_blank" variant="nostyle">
                <OpenLinkIcon />
              </Link>
            </StyledCatATBDocumentDetails>
          </li>
          <li>
            <StyledCatATBDocumentDetails>
              <ReactMarkdown linkTarget="_blank" components={{ p: 'span' }}>
                {intl.formatMessage({ id: 'decline_cat_atb_upgrade.description.current_document' })}
              </ReactMarkdown>
              &nbsp;
              <Link to="/cat_atb/new.pdf" target="_blank" variant="nostyle">
                <OpenLinkIcon />
              </Link>
            </StyledCatATBDocumentDetails>
          </li>
        </ul>
      </Paragraph>

      <Paragraph>
        <FormattedMessage id="decline_cat_atb_upgrade.description.paragraph_3" />
      </Paragraph>

      <StyledButtonContainer>
        <Button type="button" disabled={declining} onClick={handleSubmit}>
          <FormattedMessage id="decline_cat_atb_upgrade.confirm" />
        </Button>
      </StyledButtonContainer>
    </Container>
  );
}
