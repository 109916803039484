const colors = {
  main_blue: '#003264',
  main_orange: '#ffa445',
  main_green: '#32ff96',

  clear_light_blue: '#dff1fd',
  dark_gray_blue: '#56788c',
  dusty_blue: '#6675b1',
  faded_red: '#e46666',
  gray: '#666',
  gray_blue: '#d0d9e3',
  green: '#b8ef9d',
  lavender_indigo: '#956cff',
  light_gray: '#e7e7e8',
  light_peach: '#fed5b7',
  lovely_rose: '#fac8d2',
  professional_darker_blue: '#44596e',
  professional_light_blue: '#7c95ae',
  red: '#ff0000',
  sky_blue: '#68ddff',
  ultra_clear_light_blue: '#f3f9fe',
  ultra_clear_light_violet: '#fcfbff',
  ultra_light_gray: '#f7f7f8',
  ultra_light_orange: '#fff4d9',
  vibrant_light_violet: '#cbb7ff',
  vitalized_green: '#32ff96',
  warm_blue: '#a5add8',
  white: '#fff',
  light_violet: '#F5F2FF',
  ultra_light_peach: '#FFF3EB',
  periwinkle: '#D1CDFF',
  umbrella_brand_professional_light_blue: '#335B83',
};

export default colors;
