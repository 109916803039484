import styled, { css } from 'styled-components';

import { max_width_sm, min_width_md } from '../../../theme/breakpoints';
import { boxShadow, color } from '../../../theme/functions';

import { Icon } from '../../common';
import { INSURANCE_TYPE_DENTOLO, INSURANCE_TYPE_PETOLO } from '../../../shared';

const backgroundColor = (insuranceType) => {
  switch (insuranceType) {
    case INSURANCE_TYPE_DENTOLO:
      return color('ultra_clear_light_blue');
    case INSURANCE_TYPE_PETOLO:
      return color('ultra_light_gray');
    default:
      return color('ultra_clear_light_blue');
  }
};

export const StyledNavMenuMobileOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ insuranceType }) => backgroundColor(insuranceType)};
  overflow-y: hidden;
  transform: translate3d(0%, 0, 0);
  transition: transform 0.3s ease-in-out;
  z-index: 90;

  transform: ${({ open }) => `translate3d(${open ? '0%' : '100%'}, 0, 0)`};

  @media (min-width: ${min_width_md}) {
    display: none;
  }
`;

export const StyledNavMenuMobileContainer = styled.div`
  height: calc(100% - 4rem);
  margin-top: 4.5rem;
  overflow-y: auto;
`;

export const StyledNavMenuMobileUserInfo = styled.div`
  > h5 {
    font-size: 1.125rem;
    font-weight: 700;
    color: ${color('main_blue')};
  }

  > p {
    margin-top: 0.5rem;
    font-size: 1rem;
    font-weight: 450;
    color: ${color('main_blue')};
  }
`;

export const StyledUserLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  font-size: 1.2rem;
  font-weight: 700;
  color: ${color('main_green')};
  background-color: ${color('main_blue')};
  border-radius: 50%;
`;

export const StyledSignoutButtonContainer = styled.div`
  position: absolute;
  bottom: 1rem;
  right: 1rem;

  button {
    font-size: 1rem;
    font-weight: 700;
    background-color: ${color('white')};
    border: none;
    border-radius: 5px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
  }
`;

export const StyledNavMenuMobileSelectUserLink = styled.li`
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  padding: 0.5rem;
  background-color: ${color('white')};
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
  cursor: pointer;

  & + & {
    margin-top: 0.5rem;
  }

  &:focus {
    box-shadow: ${boxShadow('primary')};
  }

  ${({ isCurrentCustomer }) =>
    isCurrentCustomer &&
    css`
      cursor: default;
    `}

  ${({ inActive }) =>
    inActive &&
    css`
      opacity: 0.4;
    `}
`;

export const StyledNavMenuMobileSelectUserLinkTitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 0.5rem;

  @media (max-width: ${max_width_sm}) {
    > img {
      width: 2rem;
      height: auto;
    }
  }
`;

export const StyledNavMenuMobileSelectUserLinkTitle = styled.div`
  font-size: 1rem;

  > span {
    display: block;

    &:first-child {
      font-weight: 700;
    }

    &:last-child {
      font-size: 0.8rem;
    }
  }
`;

export const StyledNavMenuMobileSelectUserLinkCheckIcon = styled(Icon)`
  width: 2rem;
  height: auto;
`;
