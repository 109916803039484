import { FormattedMessage } from 'react-intl';

import { useInsuranceInfo } from '../../../hooks/useInsuranceInfo';
import {
  INSURANCE_TYPE_DENTOLO,
  INSURANCE_TYPE_PETOLO,
  VITOLO_CROSS_SELLING_URL,
} from '../../../shared';

import {
  StyledVitoloCrossSellingCard,
  StyledVitoloCrossSellingCardButton,
  StyledVitoloCrossSellingCardText,
  StyledVitoloCrossSellingCardTitle,
} from './VitoloCrossSellingCard.styled';
import { imgLinks } from '../../../shared';

const titleIdMap = {
  [INSURANCE_TYPE_DENTOLO]: 'promotion.carousel.vitolo.cross_selling.dentolo.title',
  [INSURANCE_TYPE_PETOLO]: 'promotion.carousel.vitolo.cross_selling.petolo.title',
};

const descriptionIdMap = {
  [INSURANCE_TYPE_DENTOLO]: 'promotion.carousel.vitolo.cross_selling.dentolo.description',
  [INSURANCE_TYPE_PETOLO]: 'promotion.carousel.vitolo.cross_selling.petolo.description',
};

const desktopImageMap = {
  [INSURANCE_TYPE_DENTOLO]: imgLinks['crossselling.dental.desktop'],
  [INSURANCE_TYPE_PETOLO]: imgLinks['crossselling.pet.desktop'],
};

const mobileImageMap = {
  [INSURANCE_TYPE_DENTOLO]: imgLinks['crossselling.dental.mobile'],
  [INSURANCE_TYPE_PETOLO]: imgLinks['crossselling.pet.mobile'],
};

function VitoloCrossSellingCard() {
  const { type: insuranceType } = useInsuranceInfo();

  const titleId = titleIdMap[insuranceType] || '';
  const descriptionId = descriptionIdMap[insuranceType] || '';
  const imgDesktop = desktopImageMap[insuranceType] || '';
  const imgMobile = mobileImageMap[insuranceType] || '';

  return (
    <StyledVitoloCrossSellingCard imgDesktop={imgDesktop} imgMobile={imgMobile}>
      <StyledVitoloCrossSellingCardTitle>
        <FormattedMessage
          id={titleId}
          values={{
            span: (chunk) => <span>{chunk}</span>,
            u: (chunk) => <u>{chunk}</u>,
          }}
        />
      </StyledVitoloCrossSellingCardTitle>

      <StyledVitoloCrossSellingCardText>
        <FormattedMessage id={descriptionId} />
      </StyledVitoloCrossSellingCardText>

      <StyledVitoloCrossSellingCardButton href={VITOLO_CROSS_SELLING_URL} target="_blank">
        <FormattedMessage id="promotion.carousel.vitolo.cross_selling.petolo.button.label" />
      </StyledVitoloCrossSellingCardButton>
    </StyledVitoloCrossSellingCard>
  );
}

export { VitoloCrossSellingCard };
