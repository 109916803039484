import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import { Paper, Separator } from '../../../components/common';
import { Radio, RadioLabel, Text } from '../../../components/common';
import { CHANGE_CANCELATION_REASON, GET_CONTRACT_CANCELLATION_REASONS } from '../../../shared';
import {
  StyledImageContainer,
  StyledCancellationReasonsTitle,
  StyledCancellationReasonsDescription,
  StyledButtonContainer,
  StyledButton,
  StyledRadioContainer,
  StyledReason,
} from './CancellationReasons.styled';

export default function CancellationReasons({
  pageTitleId,
  pageDescriptionId,
  pageIcon,
  pageIconAlt,
  paper,
}) {
  let history = useHistory();

  const [reason, setReason] = useState('');

  const { data } = useQuery(GET_CONTRACT_CANCELLATION_REASONS, {
    fetchPolicy: 'network-only',
  });
  const cancellationReasons = data?.cancellationAvailableReasons?.reasons;

  const [changeCancelationReason] = useMutation(CHANGE_CANCELATION_REASON, {
    variables: {
      cancelationReason: reason,
    },
  });

  const handleButtonClick = () => {
    if (!!reason) {
      changeCancelationReason().then(() => {
        history.push('/contract-cancellation/success');
      });
    }
  };

  return (
    <>
      <Paper transparent={!paper} hasShadow={paper}>
        {pageIcon && (
          <StyledImageContainer>
            <img src={pageIcon} alt={pageIconAlt} />
          </StyledImageContainer>
        )}

        <StyledCancellationReasonsTitle paper={paper}>
          <FormattedMessage id={pageTitleId} />
        </StyledCancellationReasonsTitle>

        <Separator />

        <StyledCancellationReasonsDescription paper={paper}>
          <FormattedMessage id={pageDescriptionId} />
        </StyledCancellationReasonsDescription>

        <StyledRadioContainer>
          {cancellationReasons &&
            Object.keys(cancellationReasons).map((item) => (
              <StyledReason
                key={item}
                onClick={() => setReason(item)}
                transparent={paper}
                hasShadow={!paper}
              >
                <Radio
                  name={'reason'}
                  onChange={(e) => {
                    e.stopPropagation();
                    setReason(item);
                  }}
                  value={item}
                  checked={item === reason}
                />
                <RadioLabel style={{ marginLeft: '1rem' }}>
                  <Text>{cancellationReasons[item]}</Text>
                </RadioLabel>
              </StyledReason>
            ))}
        </StyledRadioContainer>

        {paper && <Separator />}

        <StyledButtonContainer>
          <StyledButton variant="outline" onClick={() => history.push('/')}>
            <FormattedMessage id="cancellation.contract.button.backToHome" />
          </StyledButton>

          <StyledButton variant="primary" disabled={!reason} onClick={() => handleButtonClick()}>
            <FormattedMessage id="cancellation.contract.button.send" />
          </StyledButton>
        </StyledButtonContainer>
      </Paper>
    </>
  );
}
