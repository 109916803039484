import { INSURANCE_TYPE_DENTOLO, INSURANCE_TYPE_PETOLO, INSURANCE_TYPE_VITOLO } from '../shared';
import { useInsuranceType } from './useInsuranceType';

export const useMenuIcon = () => {
  const insuranceType = useInsuranceType();

  switch (insuranceType) {
    case INSURANCE_TYPE_DENTOLO:
      return 'icon.menu.dentolo.logo';
    case INSURANCE_TYPE_PETOLO:
      return 'icon.menu.petolo.logo';
    case INSURANCE_TYPE_VITOLO:
      return 'logo.text.vitolo';
    default:
      return 'icon.menu.dentolo.logo';
  }
};

export const useDownloadIcon = () => {
  const insuranceType = useInsuranceType();

  switch (insuranceType) {
    case INSURANCE_TYPE_DENTOLO:
      return 'icon.download.dental';
    case INSURANCE_TYPE_PETOLO:
      return 'icon.download.pet';
    case INSURANCE_TYPE_VITOLO:
      return 'icon.download.vitolo';
    default:
      return 'icon.download.dental';
  }
};

export const useDeleteIcon = () => {
  const insuranceType = useInsuranceType();

  switch (insuranceType) {
    case INSURANCE_TYPE_DENTOLO:
      return 'icon.delete.circle.dental';
    case INSURANCE_TYPE_PETOLO:
      return 'icon.delete.circle.pet';
    case INSURANCE_TYPE_VITOLO:
      return 'icon.delete.circle.violet';
    default:
      return 'icon.delete.circle.dental';
  }
};

export const useFileUploadIcon = () => {
  const insuranceType = useInsuranceType();

  switch (insuranceType) {
    case INSURANCE_TYPE_DENTOLO:
      return 'icon.plus.teal';
    case INSURANCE_TYPE_PETOLO:
      return 'icon.plus.orange';
    case INSURANCE_TYPE_VITOLO:
      return 'icon.plus.violet';
    default:
      return 'icon.plus.teal';
  }
};

export const useCheckIcon = () => {
  const insuranceType = useInsuranceType();

  switch (insuranceType) {
    case INSURANCE_TYPE_DENTOLO:
      return 'icon.success';
    case INSURANCE_TYPE_PETOLO:
      return 'icon-checked-stroke-orange';
    case INSURANCE_TYPE_VITOLO:
      return 'icon.check.vitolo.stroke';
    default:
      return 'icon.success';
  }
};

export const useEditIcon = () => {
  const insuranceType = useInsuranceType();

  switch (insuranceType) {
    case INSURANCE_TYPE_DENTOLO:
      return 'icon.pencil.blue.filled';
    case INSURANCE_TYPE_PETOLO:
      return 'icon.pencil.orange.filled';
    case INSURANCE_TYPE_VITOLO:
      return 'icon.pencil.filled.violet';
    default:
      return 'icon.pencil.blue.filled';
  }
};
