import React, { useState, useEffect } from 'react';
import { useApolloClient, useQuery } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import QueryString from 'query-string';
import JWTDecode from 'jwt-decode';
import SecondAuth from './SecondAuth';
import { useInsuranceInfo } from '../../hooks/useInsuranceInfo';
import { GET_CUSTOMERS, identifyCustomer, signInUser, INSURANCE_TYPE_PETOLO } from '../../shared';

const Authorisation = () => {
  const history = useHistory();
  const location = useLocation();
  const [birthDate, setBirthDate] = useState('');
  const [wrongDateOfBirth, setWrongDateOfBirth] = useState(false);
  const { type: insuranceType } = useInsuranceInfo();
  const isPet = insuranceType === INSURANCE_TYPE_PETOLO;
  const urlValues = QueryString.parse(location.search);
  const rememberMe = urlValues?.remember_me || false;
  const jwt = urlValues?.auth || '';

  const client = useApolloClient();

  const { loading: customersLoading, data: customersData } = useQuery(GET_CUSTOMERS);
  const validCustomersData = Array.isArray(customersData?.customers);
  const hasMultipleContracts = validCustomersData ? customersData.customers.length > 1 : false;

  useEffect(() => {
    if (Array.isArray(customersData?.customers)) {
      customersData.customers.forEach((customer) => {
        const { key } = customer || {};
        localStorage.removeItem(`customer-missing-iban-${key}`);
      });
    }
  }, [customersData]);

  useEffect(() => {
    if (!customersLoading && validCustomersData) {
      if (hasMultipleContracts) {
        history.push(`/select-customer`);
      } else {
        identifyCustomer(client, history);
      }
    }
  }, [customersLoading, validCustomersData, hasMultipleContracts, client, history]);

  useEffect(() => {
    if (birthDate) {
      const decodedJwt = jwt ? JWTDecode(jwt) : {};
      const decodedUuid = decodedJwt?.uuid;

      signInUser(client, rememberMe, jwt, decodedUuid, birthDate)
        .then((res) => {
          if (!res?.data?.signIn?.firstSignIn && isPet) {
            if (window.hj && typeof window.hj === 'function') {
              // Hotjar trigger for first login survey for pet
              window.hj('event', 'show_first_login_event');
            }
          }
        })
        .catch((error) => {
          if (error && error.message.includes("Second factor doesn't match")) {
            setWrongDateOfBirth(true);
          } else if (error && error.message.includes('Token is blocked')) {
            history.push('/login?invalid=true');
          } else {
            history.push('/authorization/failure');
          }
        });
    }
  }, [birthDate, history, jwt, rememberMe, client, isPet]);

  if (!customersLoading && !validCustomersData) {
    return (
      <SecondAuth
        jwt={jwt}
        setBirthDate={setBirthDate}
        wrongDateOfBirth={wrongDateOfBirth}
        setWrongDateOfBirth={setWrongDateOfBirth}
      />
    );
  }
  return null;
};

export default Authorisation;
