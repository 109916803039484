import styled from 'styled-components';

import { color } from '../../../theme/functions';
import { min_width_md } from '../../../theme/breakpoints';
import { promotionCarouselSliderHeight } from '../PromotionCarousel/PromotionCarousel.styled';

import { Href } from '../../../components/ui';
import { imgLinks } from '../../../shared';

export const StyledVitoloCrossSellingCard = styled.div`
  position: relative;
  padding: 1rem 1rem 0;
  width: 100%;
  height: ${promotionCarouselSliderHeight.mobile};
  background: linear-gradient(
    178deg,
    ${color('vibrant_light_violet')} 27.17%,
    #ded1ff 63.7%,
    ${color('light_violet')} 86.06%,
    ${color('white')} 94.67%
  );

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 137px;
    height: 143px;
    background: url(${({ imgMobile }) => imgMobile}) no-repeat;
    background-size: 137px 143px;
    background-position: right bottom;
  }

  @media (min-width: ${min_width_md}) {
    padding: 2rem 1.25rem 0;
    height: ${promotionCarouselSliderHeight.desktop};

    &::before {
      width: 296px;
      height: 300px;
      background-image: url(${({ imgDesktop }) => `${imgDesktop}`});
      background-size: 296px 300px;
    }
  }
`;

export const StyledVitoloCrossSellingCardTitle = styled.div`
  width: 100%;
  font-size: 1rem;
  font-weight: 700;
  color: ${color('main_blue')};

  > u {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding: 0;
    text-decoration: none;

    &::after {
      content: '';
      margin-left: -2.5rem;
      width: 43px;
      height: 4px;
      background: url(${imgLinks['crossselling.underline']}) no-repeat;
      background-size: 43px 4px;
    }
  }

  > span {
    color: ${color('white')};
  }

  @media (min-width: ${min_width_md}) {
    width: 330px;
    font-size: 1.25rem;

    > u {
      &::after {
        background-size: 60px 8px;
        width: 60px;
        height: 8px;
      }
    }
  }
`;

export const StyledVitoloCrossSellingCardText = styled.div`
  margin-top: 0.75rem;
  width: 210px;
  font-size: 0.75rem;
  color: ${color('main_blue')};

  @media (min-width: ${min_width_md}) {
    margin-top: 0.5rem;
    width: 320px;
    font-size: 0.875rem;
  }
`;

export const StyledVitoloCrossSellingCardButton = styled(Href)`
  position: relative;
  margin-top: 1rem;
  color: ${color('white')};
  text-decoration: none;
  background-color: ${color('main_blue')};

  &::after {
    content: '';
  }

  @media (min-width: ${min_width_md}) {
    &::after {
      content: '';
      background: url(${imgLinks['crossselling.arrow']}) no-repeat;
      background-size: 38px 49px;
      display: inline-block;
      position: absolute;
      right: -38px;
      top: 30%;
      width: 38px;
      height: 49px;
      -webkit-filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.15));
      filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.15));
    }
  }
`;
