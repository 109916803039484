import React from 'react';
import styled from 'styled-components';

import { useInsuranceType } from '../../hooks/useInsuranceType';
import { INSURANCE_TYPE_DENTOLO, INSURANCE_TYPE_PETOLO, INSURANCE_TYPE_VITOLO } from '../../shared';

import Dentolo404Page from './Dentolo404Page/Dentolo404Page';
import Petolo404Page from './Petolo404Page/Petolo404Page';

export default function PageNotFound() {
  const insuranceType = useInsuranceType();

  switch (insuranceType) {
    case INSURANCE_TYPE_DENTOLO:
      return <Dentolo404Page />;
    case INSURANCE_TYPE_PETOLO:
      return <Petolo404Page />;
    case INSURANCE_TYPE_VITOLO:
      return <TemporaryVitolo404Page />;
    default:
      return <></>;
  }
}

// This is a temporary 404 page for Vitolo
// It should be replaced with real one in the future
const TemporaryVitolo404Page = () => {
  return (
    <StyledTemporaryVitolo404Container>
      <StyledVitolo404Text>404</StyledVitolo404Text>
      <StyledVitoloPageNotFoundText>Oje, diese Seite existiert nicht</StyledVitoloPageNotFoundText>
    </StyledTemporaryVitolo404Container>
  );
};

const StyledTemporaryVitolo404Container = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 5rem;
`;

const StyledVitolo404Text = styled.div`
  margin-bottom: 0;
  font-size: 8rem;
  font-weight: 700;
`;
const StyledVitoloPageNotFoundText = styled.div`
  font-size: 2rem;
  font-weight: 700;
`;
