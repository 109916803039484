import { useClaimUploadFlowSource } from '../../claimUploadFlowSource';
import { VITOLO_STEP_REVIEW, VITOLO_STEP_TREATMENT_PRICE } from '../../new/newClaimsFormSteps';
import {
  PARAM_CLAIM_ID,
  VITOLO_EDIT_STEP_REVIEW,
  VITOLO_EDIT_STEP_TREATMENT_PRICE,
} from '../../edit/editClaimFormSteps';

export const useCustomerNoteVitoloBackButtonLink = (claimId) => {
  const [, /*source*/ withSource] = useClaimUploadFlowSource();

  if (!!claimId) {
    const backButtonLink = VITOLO_EDIT_STEP_TREATMENT_PRICE.replace(PARAM_CLAIM_ID, claimId);
    return withSource(backButtonLink);
  } else {
    return withSource(VITOLO_STEP_TREATMENT_PRICE);
  }
};

export const useCustomerNoteVitoloNextButtonLink = (claimId) => {
  const [, /*source*/ withSource] = useClaimUploadFlowSource();

  if (!!claimId) {
    const nextButtonLink = VITOLO_EDIT_STEP_REVIEW.replace(PARAM_CLAIM_ID, claimId);
    return withSource(nextButtonLink);
  } else {
    return withSource(VITOLO_STEP_REVIEW);
  }
};
