import styled from 'styled-components';

import { Paper } from '../../components/common';
import { min_width_lg } from '../../theme/breakpoints';
import { color } from '../../theme/functions';
import { Button } from '../../components/ui';

export const StyledAlreadyUploadedDocumentsCard = styled(Paper)`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 1em;
  padding: 1em;
`;

export const StyledAlreadyUploadedDocumentsTitle = styled.div`
  margin-bottom: 1em;

  h5 {
    font-size: 1.25rem;
    line-height: 1.25rem;
  }

  @media (min-width: ${min_width_lg}) {
    margin-bottom: 1.5em;

    h5 {
      line-height: 1.5rem;
    }
  }
`;

export const StyledAlreadyUploadedDocumentContainer = styled.div`
  padding-bottom: 1.5em;
  padding-top: 1.5em;
  border-bottom: 1px solid ${color('light_gray')};

  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    padding-bottom: 0;
    border-bottom: none;
  }
`;

export const StyledAlreadyUploadedDocument = styled.div`
  display: flex;
  column-gap: 1em;
`;

export const StyledAlreadyUploadedDocumentDate = styled.div`
  color: ${color('dark_gray_blue')};
  margin-top: 0.25em;
`;

export const StyledAlreadyUploadedDocumentLinkContainer = styled.div`
  display: flex;
  align-items: flex-end;
  column-gap: 0.25em;

  h6 {
    font-size: 1rem;
    line-height: 1.25rem;
    margin-top: 0.5em;
  }
`;

export const StyledAlreadyUploadedDocumentLinkIconButton = styled(Button)`
  display: flex;
  align-items: end;

  &::after {
    display: none;
  }
`;
