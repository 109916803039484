import styled, { css } from 'styled-components';

import { color } from '../../theme/functions';
import { min_width_md } from '../../theme/breakpoints';

import { Paper } from '../common/Paper';
import { Icon } from '../common';

const borderColorStyles = (type) =>
  ({
    info: css`
      border-color: ${color('main_blue')};
    `,
    success: css`
      border-color: ${color('green')};
    `,
    processing: css`
      border-color: ${color('gray_blue')};
    `,
    warning: css`
      border-color: ${color('faded_red')};
    `,
    danger: css`
      border-color: ${color('faded_red')};
    `,
  }[type]);

export const StyledNotificationContainer = styled(Paper)`
  position: relative;
  border-left: 5px solid;
  border-color: transparent;

  ${({ type }) => borderColorStyles(type)}
`;

export const StyledNotificationBody = styled.div`
  display: flex;
  align-items: center;

  ${({ dismissable }) =>
    dismissable &&
    css`
      margin-right: 1.75rem;
    `}
`;

export const StyledNotificationContent = styled.div`
  flex: 1;

  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;
    `}
`;

export const StyledNotificationText = styled.div`
  font-size: 1.125rem;
  color: ${color('umbrella_brand_professional_light_blue')};

  ${({ $title }) =>
    $title &&
    css`
      color: ${color('main_blue')};
      margin-bottom: 0.5rem;
      font-weight: 700;
      opacity: 1;
    `}

  ${({ noSpacing }) =>
    noSpacing &&
    css`
      margin-bottom: 0;
    `}

  @media (min-width: ${min_width_md}) {
    font-size: 1.25rem;
  }

  a {
    position: relative;
    color: ${color('umbrella_brand_professional_light_blue')};
    text-decoration: none;
    margin-top: -2px;

    &::after {
      content: '';
      position: absolute;
      height: 2px;
      bottom: -1px;
      left: 0;
      right: 0;
      width: 100%;
      background-color: ${color('umbrella_brand_professional_light_blue')};
      transform-origin: bottom left;
      transition: transform 0.2s ease-out, color 0.2s ease-out;
    }

    &:hover {
      &:after {
        transform: scaleX(0);
        transform-origin: bottom right;
      }
    }

    &:focus,
    &:active {
      &::after {
        background-color: ${color('umbrella_brand_professional_light_blue')};
        transform: scaleX(1);
        transform-origin: bottom left;
      }
    }
  }
`;

export const StyledNotificationIcon = styled(Icon)`
  width: 1.75rem;
  height: 1.75rem;
  margin-right: 1rem;
`;

export const StyledDissmissButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;
  background: transparent;
  border: none;
  cursor: pointer;

  > img {
    width: 0.625rem;
    height: 0.625rem;
  }
`;
