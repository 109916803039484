import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { StyledPromotionCarouselSlider } from './PromotionCarousel.styled';

const PromotionCarouselSlides = forwardRef((props, ref) => {
  const { settings, slides, ...rest } = props;

  return (
    <StyledPromotionCarouselSlider ref={ref} {...settings} {...rest}>
      {slides.map((slide) => (
        <React.Fragment key={slide.id}>{slide?.card}</React.Fragment>
      ))}
    </StyledPromotionCarouselSlider>
  );
});

export { PromotionCarouselSlides };

PromotionCarouselSlides.propTypes = {
  settings: PropTypes.object,
  slides: PropTypes.array.isRequired,
};
