import React from 'react';
import styled from 'styled-components';

import { THEME_DENTOLO, THEME_PETOLO, THEME_VITOLO } from '../../shared';
import { color } from '../../theme/functions';

const fill = ({ theme }) => {
  const { name, colors } = theme || {};

  switch (name) {
    case THEME_DENTOLO:
      return colors?.main_green;
    case THEME_PETOLO:
      return colors?.main_orange;
    case THEME_VITOLO:
      return colors?.vibrant_light_violet;
    default:
      return colors?.main_green;
  }
};

const StyledRadioSVG = styled.svg`
  fill: ${color('white')};
`;

const StyledRadioOutline = styled.circle`
  fill: none;
  stroke: ${color('professional_light_blue')};
  transition: stroke 0.3s ease-in-out;
`;

const StyledRadioDot = styled.circle`
  opacity: 0;
  fill: ${fill};
  transition: opacity 0.2s ease-in-out;
`;

const StyledRadioInputContainer = styled.div`
  display: flex;
  position: relative;
  margin: auto 0;

  &:hover ${StyledRadioOutline} {
    stroke: ${color('main_blue')};
  }
`;

const StyledRadioInput = styled.input`
  appearance: none;
  position: absolute;
  box-sizing: border-box;
  margin: 0;
  border: none;
  background: none;
  box-shadow: none;
  font-size: 1em;
  height: 1.5em;
  width: 1.5em;
  cursor: pointer;

  &:focus {
    border: none;
  }

  &:checked + ${StyledRadioSVG} > ${StyledRadioOutline} {
    stroke: ${color('main_blue')};
  }

  &:checked + ${StyledRadioSVG} > ${StyledRadioDot} {
    opacity: 1;
  }

  &:disabled {
    cursor: default;
  }

  &:disabled:hover + ${StyledRadioSVG} > ${StyledRadioOutline} {
    stroke: ${color('professional_light_blue')};
  }

  &:disabled:checked + ${StyledRadioSVG} > ${StyledRadioOutline} {
    stroke: ${color('professional_light_blue')};
  }

  &:disabled:checked + ${StyledRadioSVG} > ${StyledRadioDot} {
    fill: ${color('professional_light_blue')};
  }
`;

const StyledRadioLabel = styled.label`
  margin: auto 0 auto 0.25em;
  color: ${({ disabled }) => color(disabled ? 'professional_light_blue' : 'main_blue')};
  font-weight: 300;
  cursor: pointer;
`;

function Radio(props) {
  return (
    <StyledRadioInputContainer>
      <StyledRadioInput type="radio" {...props} />

      <StyledRadioSVG
        preserveAspectRatio="xMidYMid meet"
        height="24"
        width="24"
        viewBox="0 0 24 24"
      >
        <StyledRadioOutline cx="12" cy="12" r="10" strokeWidth="2" />
        <StyledRadioDot className="radioDot" cx="12" cy="12" r="7" />
      </StyledRadioSVG>
    </StyledRadioInputContainer>
  );
}

export { Radio, StyledRadioLabel as RadioLabel };
