import { useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { GET_CLAIM } from '../../../../shared';
import {
  CLAIM_UPLOAD_FLOW_SOURCE_EXISTING_CLAIM_DOCUMENT,
  CLAIM_UPLOAD_FLOW_SOURCE_NEW_CLAIM_DOCUMENT,
  CLAIM_UPLOAD_FLOW_SOURCE_UPLOAD_DOCUMENT,
  useClaimUploadFlowSource,
} from '../../claimUploadFlowSource';
import { PARAM_CLAIM_ID, PETOLO_EDIT_STEP_DOCUMENT_DATE } from '../../edit/editClaimFormSteps';
import {
  PETOLO_DOCUMENT_TYPE_INVOICE,
  PETOLO_DOCUMENT_TYPE_TREATMENT_PLAN,
  PETOLO_IS_EXISTING_CLAIM_ANSWER_NO,
  PETOLO_IS_EXISTING_CLAIM_ANSWER_YES,
  PETOLO_TREATMENT_CATEGORY_OTHERS,
} from '../../claimFormData/newClaimFormData';
import {
  PETOLO_STEP_DOCUMENT_DATE,
  PETOLO_STEP_DOCUMENT_TYPE,
  PETOLO_STEP_EXISTING_CLAIMS,
  PETOLO_STEP_EXISTING_CLAIM_QUESTION,
} from '../../new/newClaimsFormSteps';
import { isReimbursementPromised, isMissingInvoice, isMissingTreatmentPlan } from './claimUtils';

const getBackButtonLinkForNewReimbursement = (documentType, claimsCount, existingClaimAnswer) => {
  if (documentType === PETOLO_DOCUMENT_TYPE_INVOICE && claimsCount > 0) {
    if (existingClaimAnswer === PETOLO_IS_EXISTING_CLAIM_ANSWER_YES) {
      return PETOLO_STEP_EXISTING_CLAIMS;
    } else if (existingClaimAnswer === PETOLO_IS_EXISTING_CLAIM_ANSWER_NO) {
      return PETOLO_STEP_EXISTING_CLAIM_QUESTION;
    }
  }

  return PETOLO_STEP_DOCUMENT_TYPE;
};

export const useUploadTreatmentDocumentPetoloBackButtonLink = (
  claimId,
  documentType,
  claimsCount,
  existingClaimAnswer
) => {
  const [source, withSource] = useClaimUploadFlowSource();

  switch (source) {
    case CLAIM_UPLOAD_FLOW_SOURCE_NEW_CLAIM_DOCUMENT:
      const backButtonLink = getBackButtonLinkForNewReimbursement(
        documentType,
        claimsCount,
        existingClaimAnswer
      );
      return withSource(backButtonLink);
    case CLAIM_UPLOAD_FLOW_SOURCE_EXISTING_CLAIM_DOCUMENT:
      return withSource(PETOLO_STEP_EXISTING_CLAIMS);
    case CLAIM_UPLOAD_FLOW_SOURCE_UPLOAD_DOCUMENT:
      return `/user-claims-info/${claimId}`;
    default:
      return '/user-claims-info';
  }
};

const getNextButtonLinkForExistingClaim = (claim, source) => {
  //If the user goes through the New Reimbursement flow, we obtain information regarding the document
  if (
    source === CLAIM_UPLOAD_FLOW_SOURCE_NEW_CLAIM_DOCUMENT ||
    source === CLAIM_UPLOAD_FLOW_SOURCE_EXISTING_CLAIM_DOCUMENT
  ) {
    return PETOLO_EDIT_STEP_DOCUMENT_DATE;
  }

  //If the user goes through the Upload Document flow, we check the status of the claim first
  if (!!claim) {
    const reimbursementPromised = isReimbursementPromised(claim);
    const missingInvoice = isMissingInvoice(claim);
    const missingTreatmentPlan = isMissingTreatmentPlan(claim);

    if (reimbursementPromised || missingInvoice || missingTreatmentPlan) {
      return PETOLO_EDIT_STEP_DOCUMENT_DATE;
    }
  }
  return '';
};

export const useUploadTreatmentDocumentPetoloNextButtonLink = (claimId) => {
  const [source, withSource] = useClaimUploadFlowSource();

  const { data: claimData } = useQuery(GET_CLAIM, {
    variables: { id: Number(claimId) },
    skip: !claimId
  });

  if (!!claimId) {
    const nextButtonLink = getNextButtonLinkForExistingClaim(claimData?.claim, source);
    const nextButtonLinkWithClaimId = nextButtonLink.replace(PARAM_CLAIM_ID, claimId);
    return !!nextButtonLinkWithClaimId ? withSource(nextButtonLinkWithClaimId) : '';
  } else {
    return withSource(PETOLO_STEP_DOCUMENT_DATE);
  }
};

export const usePrefillSomeFormFieldsForExistingPetoloClaim = (
  claimId,
  setTreatmentCategories,
  setDocumentType
) => {
  const [source] = useClaimUploadFlowSource();

  const { data: claimData } = useQuery(GET_CLAIM, {
    variables: { id: Number(claimId) },
    skip: !claimId
  });
  const claim = claimData?.claim;

  const reimbursementPromised = isReimbursementPromised(claim);
  const missingInvoice = isMissingInvoice(claim);
  const missingTreatmentPlan = isMissingTreatmentPlan(claim);

  useEffect(() => {
    if (source === CLAIM_UPLOAD_FLOW_SOURCE_UPLOAD_DOCUMENT) {
      if (reimbursementPromised || missingInvoice) {
        setDocumentType(PETOLO_DOCUMENT_TYPE_INVOICE);
      } else if (missingTreatmentPlan) {
        setDocumentType(PETOLO_DOCUMENT_TYPE_TREATMENT_PLAN);
      }
    } else if (source === CLAIM_UPLOAD_FLOW_SOURCE_EXISTING_CLAIM_DOCUMENT) {
      setDocumentType(PETOLO_DOCUMENT_TYPE_INVOICE);
      if (!claimId) {
        setTreatmentCategories([PETOLO_TREATMENT_CATEGORY_OTHERS]);
      }
    }
  }, [
    source,
    reimbursementPromised,
    missingInvoice,
    missingTreatmentPlan,
    setDocumentType,
    setTreatmentCategories,
    claimId,
  ]);
};
