import styled from 'styled-components';
import { min_width_lg } from '../../../theme/breakpoints';

import { color } from '../../../theme/functions';

import { Paper } from '../../common';

export const StyledClaimAdditionalPaymentsCard = styled(Paper)`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 1em;
  margin-bottom: 1em;

  h5 {
    font-size: 1.25rem;
    line-height: 1.25rem;
  }

  @media (min-width: ${min_width_lg}) {
    h5 {
      line-height: 1.5rem;
    }
  }
`;

export const StyledPayoutDetailsContainer = styled.div`
  margin-top: 1em;
`;

export const StyledPayoutDate = styled.div`
  margin-bottom: 1em;
  font-size: 1em;
  font-weight: 300;
  line-height: 1.25em;
  color: ${color('dark_gray_blue')};
  opacity: 0.9;
`;

export const StyledPayoutDetails = styled.p`
  font-size: 1em;
  font-weight: 300;
  line-height: 1.25em;
  color: ${color('main_blue')};
`;
