import { useClaimUploadFlowSource } from '../../claimUploadFlowSource';
import { VITOLO_STEP_UPLOAD_DOCUMENTS } from '../../new/newClaimsFormSteps';

export const useSelectDocumentTypeVitoloBackButtonLink = () => {
  return '/user-claims-info';
};

export const useSelectDocumentTypeVitoloNextButtonLink = () => {
  const [, /*source*/ withSource] = useClaimUploadFlowSource();
  return withSource(VITOLO_STEP_UPLOAD_DOCUMENTS);
};
