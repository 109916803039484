import React from 'react';
import ListWithRadioButton from '../common/ListWithRadioButton';
import {
  useNewPetoloFormDataValue,
  PET_HEALTH_RESAON_FOR_TREATMENT,
  PET_HEALTH_RESAON_FOR_TREATMENT_GENERAL,
  PET_HEALTH_RESAON_FOR_TREATMENT_EMERGENCY,
} from '../../claimFormData/newClaimFormData';
import {
  useSelectReasonForTreatmentBackButtonLink,
  useSelectReasonForTreatmentNextButtonLink,
} from './selectReasonForTreatmentHooks';

const reasonForTreatmentConfig = [
  {
    label: 'petolo.new_claim.reason_for_treatment.general',
    value: PET_HEALTH_RESAON_FOR_TREATMENT_GENERAL,
  },
  {
    label: 'petolo.new_claim.reason_for_treatment.emergency',
    value: PET_HEALTH_RESAON_FOR_TREATMENT_EMERGENCY,
  },
];

const SelectReasonForTreatment = () => {
  const [reasonForTreatment, setDocumentType] = useNewPetoloFormDataValue(
    PET_HEALTH_RESAON_FOR_TREATMENT
  );

  const backButtonLink = useSelectReasonForTreatmentBackButtonLink();
  const nextButtonLink = useSelectReasonForTreatmentNextButtonLink();

  return (
    <ListWithRadioButton
      name="reason_for_treatment"
      backButtonLink={backButtonLink}
      nextButtonLink={nextButtonLink}
      pageTitleId={'petolo.new_claim.reason_for_treatment.page_title'}
      listConfig={reasonForTreatmentConfig}
      selected={reasonForTreatment}
      setSelected={setDocumentType}
    />
  );
};

export default SelectReasonForTreatment;
