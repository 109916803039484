import styled from 'styled-components';

import { min_width_lg } from '../../theme/breakpoints';
import { color } from '../../theme/functions';

import { Paper } from '../common';
import { Paragraph } from '../ui';

export const StyledBackButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: ${min_width_lg}) {
    flex-direction: row;
    align-items: center;
  }
`;

export const StyledHelpCenterParagraph = styled(Paragraph)`
  margin-top: 1em;
  font-size: 1em;
  font-weight: 300;
  line-height: 1.25em;
  color: ${color('main_blue')};
  background: ${color('ultra_light_gray')};
  padding: 0.5em 0.625em 0.5em 0.625em;
  border-radius: 0.125em;
`;
export const StyledClaimDetailsHeaderCard = styled(Paper)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 1em;
`;

export const StyledClaimDetailsHeaderContents = styled.div`
  margin-bottom: 0.5em;
`;

export const StyledTreatmentCategory = styled.h5`
  font-size: 1.25rem;
  line-height: 1.25rem;
  margin-bottom: 0.5em;

  @media (min-width: ${min_width_lg}) {
    line-height: 1.5rem;
    margin-bottom: 1em;
    margin-right: 0.5em;
  }
`;

export const StyledTreatmentInfo = styled.div`
  font-size: 1em;
  font-weight: 300;
  line-height: 1.25em;
  color: ${color('dark_gray_blue')};
  opacity: 0.9;
`;
