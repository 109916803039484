import styled, { css } from 'styled-components';

import { THEME_DENTOLO, THEME_PETOLO, THEME_VITOLO } from '../../shared';
import { min_width_md } from '../../theme/breakpoints';
import { color } from '../../theme/functions';

import { Tag } from '../common';
import { Paragraph } from '../ui';

export const StyledFlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 1rem;

  ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `}

  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: ${marginTop};
    `}

  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: ${marginBottom};
    `}
`;
const getTagColor = (type) => {
  switch (type) {
    case THEME_DENTOLO:
      return color('main_green');
    case THEME_PETOLO:
      return color('primary');
    case THEME_VITOLO:
      return color('light_peach');
    default:
      return color('main_green');
  }
};
const getPolicyCardColor = (theme) => {
  switch (theme?.name) {
    case THEME_DENTOLO:
      return color('secondary');
    case THEME_PETOLO:
      return color('primary');
    case THEME_VITOLO:
      return color('primary');
    default:
      return color('secondary');
  }
};
export const StyledTag = styled(Tag)`
  padding: 1rem 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.1rem;
  background-color: ${({ type }) => getTagColor(type)};
  ${({ status }) =>
    !status &&
    css`
      padding-top: 0.7rem;
      padding-bottom: 0.7rem;
      font-size: 0.75rem;
      font-weight: 700;
      background: transparent;
      border: 1px solid ${color('main_blue')};
    `}
`;

export const StyledPolicyCardText = styled.div`
  font-size: 1.1rem;
  font-weight: 450;
  color: ${color('white')};
`;

export const StyledPolicyCardTextThemed = styled(StyledPolicyCardText)``;

export const StyledPolicyCardItem = styled.div`
  & + & {
    margin-top: 1rem;
  }

  ${StyledPolicyCardTextThemed} {
    color: ${({ theme }) => getPolicyCardColor(theme)}

  ${({ type }) =>
    type === 'title' &&
    css`
      display: flex;
      justify-content: space-between;
      margin-bottom: 1.5rem;

      color: ${color('white')};

      ${StyledPolicyCardText} {
        font-size: 1.25rem;
        font-weight: 700;
      }

      ${StyledPolicyCardTextThemed} {
        font-size: 1.25rem;
        font-weight: 700;
      }
    `}
`;

export const StyledPolicyAmount = styled.div`
  display: inline-block;
  margin-right: 1rem;
  min-width: 4rem;
`;

export const StyledContractCancelLinkParagraph = styled(Paragraph)`
  margin-top: 1rem;

  @media (min-width: ${min_width_md}) {
    margin-top: 1.5rem;
  }
`;

export const StyledMissingIbanBannerContainer = styled.div`
  margin-bottom: 1rem;
`;
