import styled from 'styled-components';

import { min_width_lg } from '../../theme/breakpoints';
import { boxShadow, color } from '../../theme/functions';
import { THEME_DENTOLO, THEME_PETOLO, THEME_VITOLO } from '../../shared';

const boxShadowMap = {
  [THEME_DENTOLO]: boxShadow('box_shadow_3'),
  [THEME_PETOLO]: boxShadow('box_shadow_3'),
  [THEME_VITOLO]: boxShadow('primary'),
};

const disabledColor = {
  [THEME_DENTOLO]: color('gray'),
  [THEME_PETOLO]: color('gray'),
  [THEME_VITOLO]: color('dusty_blue'),
};

const StyledInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  height: 3.5em;
  margin: 0;
  padding: 0.5em;
  font-size: 1em;
  font-weight: 300;
  color: ${color('main_blue')};
  background-color: ${color('white')};
  border: ${color('main_blue')} 1px solid;
  border-radius: 4px;
  appearance: none;

  &[disabled] {
    background: ${color('ultra_light_gray')};
    border-color: ${({ theme }) => disabledColor[theme?.name] || color('gray')};
    color: ${({ theme }) => disabledColor[theme?.name] || color('gray')};
    cursor: not-allowed;
  }

  &:has(+ div) {
    border-color: ${color('red')} !important;
    box-shadow: none !important;
  }
  &[data-error] {
    border-color: ${color('red')} !important;
    box-shadow: none !important;
  }
  @media only screen and (min-width: ${min_width_lg}) {
    padding: 0.5em 1em;
  }

  &::placeholder {
    color: ${color('main_blue')};
    opacity: 0.6;
  }

  &:focus {
    outline: none;
    box-shadow: ${({ theme }) => boxShadowMap[theme?.name] || boxShadow('box_shadow_3')};
    border-color: ${color('main_blue')};
    color: ${color('main_blue')};

    ::placeholder {
      color: ${color('main_blue')};
    }
  }

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }
`;

export { StyledInput as Input };
