import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { CSSTransition } from 'react-transition-group';
import { useQuery } from '@apollo/client';

import { useInsuranceType } from '../../hooks/useInsuranceType';
import { getMenuLinksConfig } from './navMenuUtils';
import {
  useSelectCustomer,
  INSURANCE_TYPE_DENTOLO,
  INSURANCE_TYPE_PETOLO,
  GET_CUSTOMER,
  DA_DIREKT_POLICY_CATEGORIES,
  INSURANCE_TYPE_VITOLO,
  RISK_CARRIER_AMTRUST,
  SPECIAL_TARIFF_POLICY_CATEGORIES,
} from '../../shared';
import {
  myProfileLinkItems,
  miscellaneousLinkItems,
  petHelpLinkItems,
  vitoloHelpLinkItems,
} from './navMenuConfig';

import NavLinkDropdown from './NavLinkDropdown';
import {
  StyledNavLink,
  StyledNavMenuDesktop,
  StyledNavMenuDesktopContainer,
  StyledNavExternalLink,
} from './styled/NavMenuDesktop.styled';

function NavMenuDesktop() {
  const { data: customerData } = useQuery(GET_CUSTOMER);
  const customerInsuranceCategory = customerData?.customer?.insuranceCategory;
  const customerPolicyCategory = customerData?.customer?.contract?.policyCategory;
  const [ready, setReady] = useState(false);
  const location = useLocation();

  const insuranceType = useInsuranceType();

  const isAmtrustCustomer = customerData?.customer?.contract?.riskCarrier === RISK_CARRIER_AMTRUST;

  const isDaDirektCustomer =
    Number.isInteger(customerPolicyCategory) &&
    DA_DIREKT_POLICY_CATEGORIES.includes(customerPolicyCategory);

  const isSpecialTariffCustomer =
    Number.isInteger(customerPolicyCategory) &&
    SPECIAL_TARIFF_POLICY_CATEGORIES.includes(customerPolicyCategory);

  const menuLinksConfig = getMenuLinksConfig(
    insuranceType,
    customerInsuranceCategory,
    isDaDirektCustomer,
    isAmtrustCustomer,
    isSpecialTariffCustomer
  );

  const menuHelpLinksMap = {
    [INSURANCE_TYPE_DENTOLO]: miscellaneousLinkItems,
    [INSURANCE_TYPE_PETOLO]: petHelpLinkItems,
    [INSURANCE_TYPE_VITOLO]: vitoloHelpLinkItems,
  };

  useEffect(() => {
    if (!ready) setReady(true);
  }, [ready]);

  const handleNavLinkClick = (ev, linkItem) => {
    if (location?.pathname === linkItem.path) {
      ev.preventDefault();
    }
  };

  const handleExternalNavLinkClick = (ev, linkItem) => {
    if (ev) ev.preventDefault();

    window.open(linkItem.link, '_blank');
  };

  if (useSelectCustomer(location?.pathname)) {
    return <></>;
  }

  return (
    <CSSTransition in={ready} timeout={400} classNames="nav-menu-desktop" unmountOnExit>
      <StyledNavMenuDesktop data-nav-menu-desktop>
        <StyledNavMenuDesktopContainer>
          <NavLinkDropdown
            caption={<FormattedMessage id={`menu.header.item.my_profile`} />}
            linkItems={myProfileLinkItems}
          />

          {menuLinksConfig.map((linkItem) =>
            !!linkItem?.externalUrl ? (
              <StyledNavExternalLink
                key={linkItem?.title}
                onClick={(ev) => handleExternalNavLinkClick(ev, linkItem)}
              >
                {linkItem?.title}
              </StyledNavExternalLink>
            ) : (
              <StyledNavLink
                key={linkItem.path}
                to={linkItem.path}
                onClick={(ev) => handleNavLinkClick(ev, linkItem)}
              >
                {linkItem.title}
              </StyledNavLink>
            )
          )}

          <NavLinkDropdown
            caption={<FormattedMessage id={`menu.header.item.help`} />}
            linkItems={menuHelpLinksMap[insuranceType] || miscellaneousLinkItems}
          />
        </StyledNavMenuDesktopContainer>
      </StyledNavMenuDesktop>
    </CSSTransition>
  );
}

export default NavMenuDesktop;
