import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Container, PageTitle, Paragraph } from '../../components/common';
import { OutlinedCheckIcon } from '../../components/common/Icon';

import { StyledIconContainer } from './DeclineCatATBUpgrade.styled';

export default function DeclineCatATBUpgradeSuccess() {
  return (
    <Container style={{ textAlign: 'center' }}>
      <StyledIconContainer>
        <OutlinedCheckIcon />
      </StyledIconContainer>

      <PageTitle>
        <FormattedMessage id="decline_cat_atb_upgrade.confirm.success.title" />
      </PageTitle>

      <Paragraph>
        <FormattedMessage id="decline_cat_atb_upgrade.confirm.success.description" />
      </Paragraph>
    </Container>
  );
}
