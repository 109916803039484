import styled, { css } from 'styled-components';

import { min_width_lg } from '../../../theme/breakpoints';
import { color } from '../../../theme/functions';

import {
  BLOCKED,
  CANCELLED,
  CLOSED,
  DECLINED,
  NEW,
  PARTIALLY_CLOSED,
  REIMBURSEMENT_ACKNOWLEDGED,
  WAITING_FOR_INFORMATION,
  WAITING_FOR_PAYOUT,
} from '../../../shared/claimStatus';

import { Paper } from '../../common';
import { Button } from '../Button';

export const StyledClaimStatusCard = styled(Paper)`
  position: relative;
  width: 100%;
  margin-bottom: 1em;
  padding: 0;

  @media (min-width: ${min_width_lg}) {
    margin-bottom: 1.5em;
  }
`;

export const StyledClaimStatusCardButton = styled(Button)`
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding: 0.5em;
  background-color: #fff;
  border-radius: 5px;
  cursor: pointer;

  border: ${(props) => (props.statusUpdated ? '2px solid #003264' : '2px solid #fff')};

  &:hover,
  &:focus,
  &:active {
    border: 2px solid #7c95ae;
    box-shadow: none;
  }

  @media (min-width: ${min_width_lg}) {
    padding: 1em;
  }
`;

export const StyledArrowIcon = styled.div`
  position: absolute;
  top: 50%;
  right: 1.15rem;
  transform: translateY(-50%);
  background: ${color('primary')};
  display: flex;
  border-radius: 7px;
  width: 2rem;
  height: 2rem;
  justify-content: center;
  align-items: center;
`;

export const StyledTreatmentDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 0.5em;
`;

export const StyledTreatmentDetails = styled.h5`
  margin-bottom: 0.5em;
  font-size: 1.25rem;
  word-break: break-word;
  text-align: left;

  ${StyledClaimStatusCardButton}:hover & {
    color: #7c95ae;
  }
`;

export const StyledTreatmentOtherInfo = styled.div`
  color: ${color('professional_light_blue')};
  font-size: 1em;
  font-weight: 300;
  line-height: 1.25em;

  ${StyledClaimStatusCardButton}:hover & {
    color: #7c95ae;
  }
`;

const claimStatusLabelCSS = (props) => {
  const { claimStatus } = props;

  switch (claimStatus) {
    case BLOCKED:
      return css`
        background-color: ${color('faded_red')};
        color: ${color('white')};
      `;

    case REIMBURSEMENT_ACKNOWLEDGED:
    case WAITING_FOR_PAYOUT:
    case PARTIALLY_CLOSED:
    case CLOSED:
    case CANCELLED:
      return css`
        background-color: ${color('green')};
      `;

    case DECLINED:
      return css`
        border: 1px solid ${color('main_blue')};
      `;

    case NEW:
    case WAITING_FOR_INFORMATION:
    default:
      return css`
        background-color: ${color('gray_blue')};
      `;
  }
};

export const StyledClaimStatus = styled.div`
  align-self: flex-start;
  padding: 0.25em 0.375em;
  font-weight: 700;
  line-height: 1em;
  color: ${color('main_blue')};
  border-radius: 5px;
  ${claimStatusLabelCSS};

  span {
    font-size: 0.75em;
  }
`;
