import React from 'react';
import { useQuery } from '@apollo/client';
import { Redirect } from 'react-router-dom';

import { GET_CUSTOMER } from '../../shared';

const AFFECTED_POLICY_IDS = ['190', '191', '192', '193', '194', '195', '196'];

export function isMigratedCatATBContract(customerData) {
  const contractAcceptedAt = customerData?.contract?.acceptedAt;

  // Exact timestamp of the very first customer who signed up with the new policy version
  const contractSignedBeforeDeploymentDate =
    new Date(contractAcceptedAt) < new Date('2024-08-05 10:11:34 +0200');

  const policyId = customerData?.policyId;
  const isAffectedPolicy = AFFECTED_POLICY_IDS.includes(policyId);

  const isMigrated = contractSignedBeforeDeploymentDate && isAffectedPolicy;

  return isMigrated;
}

export default function PageRenderForCatATBUpgrade({ children }) {
  const { data: customerData } = useQuery(GET_CUSTOMER);

  const renderPage = isMigratedCatATBContract(customerData?.customer);

  if (renderPage) {
    return <>{children}</>;
  } else {
    return <Redirect to="/404" />;
  }
}
