import React from 'react';
import { useInsuranceType } from '../../../hooks/useInsuranceType';
import CancellationReasons from './CancellationReasons';
import {
  CANCELLATION_TYPE_REGULAR,
  CANCELLATION_TYPE_WITHDRAWAL_BY_CUSTOMER,
} from '../ContractCancellationConstants';
import {
  imgLinks,
  INSURANCE_TYPE_DENTOLO,
  INSURANCE_TYPE_PETOLO,
  INSURANCE_TYPE_VITOLO,
} from '../../../shared';

const getPageTitleId = (cancellationType) => {
  switch (cancellationType) {
    case CANCELLATION_TYPE_WITHDRAWAL_BY_CUSTOMER:
      return 'cancellation.contract.withdrawl.success.title';
    case CANCELLATION_TYPE_REGULAR:
      return 'cancellation.contract.regular.success.title';
    default:
      return 'cancellation.contract.regular.success.title';
  }
};

const getPageDescriptionId = (cancellationType) => {
  switch (cancellationType) {
    case CANCELLATION_TYPE_WITHDRAWAL_BY_CUSTOMER:
      return 'cancellation.contract.withdrawl.success.description';
    case CANCELLATION_TYPE_REGULAR:
      return 'cancellation.contract.regular.success.description';
    default:
      return 'cancellation.contract.regular.success.description';
  }
};

export default function CancellationReasonsIndex({ cancellationType }) {
  const insuranceType = useInsuranceType();

  const pageTitleId = getPageTitleId(cancellationType);
  const pageDescriptionId = getPageDescriptionId(cancellationType);

  switch (insuranceType) {
    case INSURANCE_TYPE_DENTOLO:
      return (
        <CancellationReasons
          pageTitleId={pageTitleId}
          pageDescriptionId={pageDescriptionId}
          pageIcon={imgLinks['icon.user.dental.cancellation.success']}
          pageIconAlt={'tooth'}
          paper={true}
        />
      );
    case INSURANCE_TYPE_PETOLO:
      return (
        <CancellationReasons
          pageTitleId={pageTitleId}
          pageDescriptionId={pageDescriptionId}
          pageIcon={imgLinks['icon.user.pet.cancellation.success']}
          pageIconAlt={'pet'}
          paper={true}
        />
      );
    case INSURANCE_TYPE_VITOLO:
      return (
        <CancellationReasons
          pageTitleId={pageTitleId}
          pageDescriptionId={pageDescriptionId}
          paper={false}
        />
      );
    default:
      return <></>;
  }
}
