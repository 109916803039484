import { useClaimUploadFlowSource } from '../../claimUploadFlowSource';
import {
  PARAM_CLAIM_ID,
  VITOLO_EDIT_STEP_DOCUMENT_DATE,
  VITOLO_EDIT_STEP_UPLOAD_DOCUMENTS,
  VITOLO_EDIT_STEP_TREATMENT_PRICE,
} from '../../edit/editClaimFormSteps';

import {
  VITOLO_STEP_DOCUMENT_TYPE,
  VITOLO_STEP_CUSTOMER_NOTE,
  VITOLO_STEP_UPLOAD_DOCUMENTS,
} from '../../new/newClaimsFormSteps';

export const useSubmitClaimFormVitoloBackButtonLink = (claimId) => {
  const [, /*source*/ withSource] = useClaimUploadFlowSource();

  if (!!claimId) {
    const backButtonLinkWithClaimId = VITOLO_EDIT_STEP_TREATMENT_PRICE.replace(
      PARAM_CLAIM_ID,
      claimId
    );
    return withSource(backButtonLinkWithClaimId);
  } else {
    return withSource(VITOLO_STEP_CUSTOMER_NOTE);
  }
};

export const useEditDetailsLinkVitolo = (claimId) => {
  const [, /*source*/ withSource] = useClaimUploadFlowSource();

  if (!!claimId) {
    const editButtonLink = VITOLO_EDIT_STEP_DOCUMENT_DATE.replace(PARAM_CLAIM_ID, claimId);
    return withSource(editButtonLink);
  } else {
    return withSource(VITOLO_STEP_DOCUMENT_TYPE);
  }
};

export const useEditDocumentsLinkVitolo = (claimId) => {
  const [, /*source*/ withSource] = useClaimUploadFlowSource();

  if (!!claimId) {
    const editDocumentsLink = VITOLO_EDIT_STEP_UPLOAD_DOCUMENTS.replace(PARAM_CLAIM_ID, claimId);
    return withSource(editDocumentsLink);
  } else {
    return withSource(VITOLO_STEP_UPLOAD_DOCUMENTS);
  }
};
