import {
  PETOLO_IMPRINT_LINK,
  PETOLO_DATA_PROTECT_LINK,
  PETOLO_HELP_LINK,
  DENTOLO_IMPRINT_LINK,
  DENTOLO_DATA_PROTECT_LINK,
  INSURANCE_TYPE_DENTOLO,
  INSURANCE_TYPE_PETOLO,
  INSURANCE_TYPE_VITOLO,
  VITOLO_IMPRINT_LINK,
  VITOLO_DATA_PROTECT_LINK,
  VITOLO_HELP_LINK,
  DENTOLO_HELP_LINK,
} from '../../shared';

export const getFooterCopyright = (insuranceType) => {
  switch (insuranceType) {
    case INSURANCE_TYPE_DENTOLO:
      return '© dentolo Deutschland GmbH';
    case INSURANCE_TYPE_PETOLO:
      return 'petolo ist eine Marke der © dentolo Deutschland GmbH';
    case INSURANCE_TYPE_VITOLO:
      return 'vitolo ist eine Marke der © dentolo Deutschland GmbH';
    default:
      return '© dentolo Deutschland GmbH';
  }
};

export const getImprintLink = (insuranceType) => {
  switch (insuranceType) {
    case INSURANCE_TYPE_DENTOLO:
      return DENTOLO_IMPRINT_LINK;
    case INSURANCE_TYPE_PETOLO:
      return PETOLO_IMPRINT_LINK;
    case INSURANCE_TYPE_VITOLO:
      return VITOLO_IMPRINT_LINK;
    default:
      return DENTOLO_IMPRINT_LINK;
  }
};

export const getDateProtectionLink = (insuranceType) => {
  switch (insuranceType) {
    case INSURANCE_TYPE_DENTOLO:
      return DENTOLO_DATA_PROTECT_LINK;
    case INSURANCE_TYPE_PETOLO:
      return PETOLO_DATA_PROTECT_LINK;
    case INSURANCE_TYPE_VITOLO:
      return VITOLO_DATA_PROTECT_LINK;
    default:
      return DENTOLO_DATA_PROTECT_LINK;
  }
};

export const getSupportLink = (insuranceType) => {
  switch (insuranceType) {
    case INSURANCE_TYPE_DENTOLO:
      return DENTOLO_HELP_LINK;
    case INSURANCE_TYPE_PETOLO:
      return PETOLO_HELP_LINK;
    case INSURANCE_TYPE_VITOLO:
      return VITOLO_HELP_LINK;
    default:
      return DENTOLO_HELP_LINK;
  }
};
